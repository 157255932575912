



<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>


  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>

    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Get $250 Off Invisalign!

        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Free Invisalign Consultation
      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>

      </p>
        <ul>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">               Free smile exam to assess your teeth and bite
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Get personalized treatment based on your unique smile
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">          Obtain a clear estimate of the costs of your treatment
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Explore payment plans that fit your budget
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Get an additional $250 off invisalign
          </span>
        </li>
      </ul>


      <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>


        <p class="hidden mx-auto text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mt-14">
          Over <span class='font-semibold text-purple-600'>25 years</span> of experience <br>
        </p>


      </div>



    </div>


 <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl"  src="../../../../assets/images1/invisalign_1.webp" alt="Beautiful Smile">

      </div>
    </div>


  </div>


  <div class='flex flex-col items-center mt-20 lg:mt-24  lg:flex '>

    <p class="text-xl  tracking-wider text-purple-900 uppercase">
      Rated 5 stars by over <span class='font-semibold text-purple-600'>100 patients</span>
    </p>
    <div class='flex flex-col overflow-hidden  sm:flex-row flex-start'>

      <div class='flex flex-col items-center pt-5 '>
        <div class='flex justify-center w-full'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>
        <p class='text-lg  tracking-wide text-purple-700 uppercase'>
          Google Reviews
        </p>

        <div class='mt-10 font-semibold lg:mt-12'>
          <a href="tel:909-341-1678" class='mt-6 text-xl font-medium text-black bg-red-500 sm:mt-0  relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
            Call Now



          </a>
        </div>
      </div>
    </div>
  </div>
</section>


<section loading="lazy" class='px-4 pb-5 overflow-hidden bg-purple-100 sm:pb-24 sm:px-6 lg:px-8 pt-24'>
  <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Benefits of Invisalign
  </h1>
  <div class="mt-10 lg:flex justify-center mx-auto">
    <div class='max-w-2xl lg:mr-4'>
      <ul class='list-disc text-xl text-purple-800 mx-auto pl-5'>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Nearly Invisible Aligners: Discreet treatment with clear aligners.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Removable for Flexibility: Easily take them out for eating and cleaning.</span>
        </li>


        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Less Time-Consuming: Fewer visits to the orthodontist required.</span>
        </li>

        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Boost in Self-Confidence: A straighter smile to boost your self-esteem.</span>
        </li>

        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Ideal for Active Lifestyles: Safer for sports and physical activities.</span>
        </li>

      </ul>
    </div>
  </div>
  <div class='text-center max-w-xl mx-auto lg:max-w-screen-xl'>
    <p class='mt-10 text-lg text-purple-800'>
      At Packing House Dental & Orthodontics in Redlands, we combine advanced technology with personalized care to give you the best Invisalign experience. Start your journey towards a perfect smile today!
    </p>
  </div>
</section>


<section loading="lazy" class=' bg-gradient-to-b from-purple-100 to-yellow-50  '>

  <div class='px-4 mx-auto lg:max-w-screen-2xl sm:px-6'>

    <div class='flex flex-col items-center justify-center'>
      <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Reviews
      </h1>

    </div>

    <div class='grid gap-8 mt-12 md:gap-8 sm:gap-6 md:mt-14 lg:mt-16 xl:mt-20 2xl:mt-24 xl:grid-cols-4 sm:grid-cols-2 2xl:gap-12 lg:gap-6'>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-yellow-200 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img loading="lazy" src='../../../../assets/images1/d.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt='Testimonial 01'>
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Devyn
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          “I had my braces done by Dr. Ketcherside and he did an amazing job. Him and his staff are always so welcoming, kind and very helpful. I highly recommend going to Dr. Ketcherside. You will not be disappointed."        </p>
      </blockquote>

      <blockquote class='px-8 py-8  bg-purple-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl hidden lg:block'>
        <img loading="lazy" src='../../../../assets/images1/a.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full  ' alt="Testimonial 02">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Autumn
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          "Friendly staff, the office is really nice and clean. This was my first visit and they explained all the procedures clearly and made me feel comfortable."        </p>
      </blockquote>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-rose-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl hidden lg:block'>
        <img src='../../../../assets/images1/g.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt="Testimonial 03">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          G J
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          “This office is the best! The front desk staff are amazing and friendly!  Dr Ketcherside is very kind and professional. I highly recommend this office to anyone looking for dental care and to be treated like royalty while you are there.”        </p>
      </blockquote>

      <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-blue-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
        <img  loading="lazy" src='../../../../assets/images1/k.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt="Testimonial 04">
        <p class='mt-3 text-lg font-bold text-purple-900'>
          Katie
        </p>

        <div class='mt-1 flex justify-start w-full space-x-0.5'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>

        <p class='mt-5 text-lg text-purple-800'>
          "We have been coming to Dr. Ketcherside in Corona for over 7yrs. Myself and my 3 children has had amazing work done on our teeth by the Dr. The Dr & Staff are amazing!!!  Super welcoming and friendly. Clean office and hands down give out the best cookies."        </p>
      </blockquote>

    </div>

  </div>

</section>


<section class="bg-gradient-to-b from-yellow-50 to-purple-100 h-auto pb-20">

  <div class='max-w-screen-xl mx-auto'>

    <div class="relative">

      <h2 class="max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold">
       How it works
      </h2>


    </div>

    <div class='max-w-xl mx-auto mt-16 lg:max-w-none sm:mt-20 lg:mt-24 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24'>




      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-7 lg:row-start-1'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Free Consultation
          </span>
        </div>


        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          1. Come in for a free invisalign consultation
        </h3>



        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>


          Your first visit will consist of diagnostic records, an exam, your smile goals, and financing options. Each patient’s invisalign treatment is different so we leave ample time for questions.


          <!-- After you receive your quote show your treatment coordinator a picture of the coupon below to receive an additional $250 off your invisalign treatment -->

      </p>

        <div class='mt-6'>

        </div>
      </div>
      <div class='relative lg:col-span-6 lg:col-start-1 lg:row-start-1'>
        <div class='w-full rounded-3xl px-10 lg:px-0'>
          <div class="aspect-w-2 aspect-h-3 rounded-2xl pt-16">
            <img class="object-cover rounded-2xl" loading="lazy" src="../../../../assets/images1/consult_2.webp" alt="Invisalign Consultation">
          </div>

        </div>
      </div>



    </div>

    <div class='max-w-xl mx-auto mt-16 sm:mt-44 lg:mt-56 xl:mt-60 2xl:mt-64 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>



      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Quick and Easy
          </span>
        </div>


        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          2. Begin Treatment
        </h3>



        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>


            Next we'll create aligners to gently push your teeth into their correct position. You will have appointments every 6 weeks to check on your progress and give you the next aligner. Treatment times vary but are usually about 18 months



      </p>

        <div class='mt-6'>

        </div>
      </div>
      <div class='relative lg:order-2 lg:col-span-6'>
        <div class='relative '>
          <div class="w-full rounded-3xl px-10 lg:px-0">
            <img class="object-cover rounded-3xl "  loading="lazy" src="../../../../assets/images1/invisalign_1.webp" alt="girl with beautiful smile">
          </div>

        </div>
      </div>

    </div>

    <div class='max-w-xl mx-auto mt-16 sm:mt-44 lg:mt-56 xl:mt-60 2xl:mt-64 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>



      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-7 lg:row-start-1'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Enjoy your new smile
          </span>
        </div>


        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          3. Smile
        </h3>



        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>


            Once you've finished your treatment show off your new smile!


      </p>

        <div class='mt-6'>

        </div>
      </div>
      <div class='relative lg:order-2 lg:col-span-6 lg:col-start-1 lg:row-start-1'>
        <div class='w-full rounded-3xl px-10 lg:px-0'>
          <div class="aspect-w-3 aspect-h-4 rounded-3xl">
            <img class="object-cover rounded-3xl"  loading="lazy" src="../../../../assets/images1/smile_1.webp" alt="girl with beautiful smile">
          </div>

        </div>
      </div>

    </div>

  </div>


</section>



<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-purple-100 sm:pb-24 sm:px-6 lg:px-8 outline-1 pt-10'>



  <div class="mx-auto pb-7 text-center ">
    <span class='inline-block px-4 mx-auto text-center py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
      Pricing and financing!

    </span>
  </div>
  <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Invisalign Cost
  </h1>
    <p class='max-w-2xl mt-3 text-xl text-center mx-auto leading-loose text-purple-800 sm:mt-4'>
      Invisalign usually costs between $3000 and $8000 but the exact price for invisalign is dependant upon the complexity of your treatment. Depending upon your teeth dental insurance could cover your treatment. We accept all PPO dental insurances. We also offer carecredit and in-house financing for affordable monthly payments.


    </p>







<div class='max-w-xl mx-auto lg:max-w-screen-xl '>




    <!-- Enroll CTA-->
    <div class='mt-10 font-semibold lg:mt-12 mx-auto text-center'>
      <a href="tel:9093411678" class='mt-6 text-lg font-medium text-black bg-yellow-300 sm:mt-0 sm:ml-6 relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
        Call Now



      </a>
    </div>






</div>





</section>

<section class='relative px-4  overflow-hidden  sm:px-6 lg:px-8 bg-gradient-to-b from-purple-100 to-yellow-50'>

   <div class='relative z-10  sm:mt-16'>
    <div class=' sm:aspect-w-16 sm:aspect-h-9'>
      <img class='flex justify-center align-middle mx-auto object-cover w-2/3 lg:w-1/3 shadow-xl rounded-3xl ' src='../../../../assets/images1/250.webp' alt='Invisalign Discount' />
    </div>
  </div>

</section>



<section class="bg-yellow-50 h-auto pb-10 pt-10">

  <div class='flex flex-col justify-center align-middle max-w-3xl mx-auto lg:w-1/2 w-3/4 lg:mt-0 lg:max-w-none lg:col-span-6'>
    <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Send us a message
          </h3>
          <p class='text-purple-800 mt-0.5 text-opacity-90'>
            We'll get back to you as soon as possible
          </p>
        </div>

        <div class="pt-7">
          </div>



          <form class="contact-form" (submit)="sendEmail($event)">
            <label class="block">
              <span class="text-gray-700">Your name:</span>
              <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
            </label>
            <!-- Email Input -->
       
            <!-- Phone Number Input -->
            <label class="block">
              <span class="text-gray-700">Phone number:</span>
              <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="(909) 456-7890">
            </label>
  
            <label class="block">
              <span class="text-gray-700">Prefer Contact Method:</span>
              <select name="contact-preference" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                <option value="text">Text</option>
                <option value="call">Call</option>
              </select>
            </label>
  
            <!-- Message Input -->
            <label class="block">
              <span class="text-gray-700">Your message:</span>
              <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
            </label>
  
            <label class="hidden">
              <input name="location" value="Redlands Office" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
            </label>
  
            <label class="hidden">
              <input name="page" value="dental-wisdomteeth-extractions" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
            </label>
  
            <!-- Submit Button -->
            <button type="submit" value="Send"  class="inline-flex justify-center py-2 mt-6 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Send
            </button>
          </form>
        </div>
  </div>


</section>

<section class='relative w-full px-4 pt-10 bg-white -mb-52    sm:px-6 lg:px-8'>

  <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Located in Corona
  </h1>
  <div class=' lg:mx-auto lg:mt-24 rounded-3xl lg:max-w-screen-xl'>
    <iframe class='w-full rounded-3xl'
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13252.92396507248!2d-117.5556445!3d33.8579374!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x43116ec56c21e3be!2sKetcherside%20Orthodontics%20Inc.!5e0!3m2!1sen!2sus!4v1664564711858!5m2!1sen!2sus"
      height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</section>



<section loading="lazy" class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' width="300" height="128"loading="lazy" src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Ketcherside Orthodontics Inc</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="underline">
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9093411678" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call Now

        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 " width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" alt="image of phone">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>
