<section class='bg-gradient-to-b from-teal-50 to-teal-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-teal-700 bg-teal-200 rounded-full shadow-md -rotate-1'>
          Stunning Smiles
        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-teal-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
         Dental Veneers
      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-teal-800 lg:text-left'>
        Here at Packing House Dental & Orthodontics in Redlands, we use high-quality porcelain veneers that provide long-lasting results. Whether you have chipped or discolored teeth, porcelain veneers can transform your teeth and smile into a beautiful attribute of your appearance.
        </p>
        <ul>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Detailed assessment for a perfect veneer fit</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Tailored solutions for your smile aesthetics</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Transparent pricing with no hidden costs</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Flexible financing options available through CareCredit</span>
        </li>

      </ul>
      <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>
        <p class="hidden mx-auto text-lg font-medium tracking-wider text-teal-900 uppercase sm:block lg:hidden xl:block mt-14">
          Over <span class='font-semibold text-teal-600'>30 years</span> of dental experience <br>
        </p>
      </div>
    </div>
    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/veneers.webp" alt="Stunning Veneers Smile">
      </div>
    </div>
  </div>
</section>

<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-teal-100 sm:pb-24 sm:px-6 lg:px-8 pt-24'>
  <h1 class='text-4xl text-teal-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Reasons for Dental Veneers
  </h1>
  <div class="mt-10 lg:flex justify-center mx-auto">
    <div class='max-w-2xl lg:mr-4'>
      <ul class='list-disc text-xl text-teal-800 mx-auto justify-center pl-5'>
        <li class="flex items-center my-2">
          <span class="text-lg">Aesthetic Improvement: Provides a brighter and more uniform smile.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Durability: Veneers are long-lasting and resistant to stains and chips.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Minimal Tooth Alteration: Requires less enamel removal than crowns or caps.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Customization: Shaped and colored to match your natural teeth for a seamless look.</span>
        </li>
<!-- 
        <li class="flex items-center my-2">
          <span class="text-lg">Chipped or Broken Teeth.</span>
        </li>

        <li class="flex items-center my-2">
          <span class="text-lg">Crooked Teeth.</span>
        </li>

        <li class="flex items-center my-2">
          <span class="text-lg">Cracked Teeth.</span>
        </li>

        <li class="flex items-center my-2">
          <span class="text-lg">Misshapen Teeth.</span>
        </li>

        <li class="flex items-center my-2">
          <span class="text-lg">Gaps Between Teeth.</span>
        </li>

        <li class="flex items-center my-2">
          <span class="text-lg">Worn-down Teeth.</span>
        </li>
        <li class="flex items-center my-2">
          <span class="text-lg">Stain or Discolored Teeth.</span>
        </li> -->

      </ul>
    </div>
  </div>
  <div class='text-center max-w-xl mx-auto lg:max-w-screen-xl'>
    <p class='mt-10 text-lg text-teal-800'>
      At Packing House Dental & Orthodontics, we craft each set of veneers to perfection, ensuring a natural and appealing look that complements your overall appearance.
    </p>
  </div>
</section>


<section loading="lazy" class='px-4 pb-12 overflow-hidden bg-purple-100 lg:pt-24 sm:px-6 lg:px-8'>

  <div class='max-w-xl mx-auto lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-32 lg:max-w-screen-xl '>

    <div class='py-16 lg:py-32'>
      <div>

      </div>
      <h1 class='max-w-md mt-4 text-purple-900 h1'>
        Get A Consultation
      </h1>
      <p class='max-w-lg mt-3 text-xl leading-relaxed text-purple-800'>
        Are you in need of Veneers or a dental consulation? Look no further! Our dental clinic is now offering consultation services to address all your dental concerns. Fill out the form and we'll contact you as soon as possible.


      </p>
    </div>

    <div class='relative'>



      <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Contact Form
          </h3>

        </div>

        <div class="pt-7"></div>
        <form class="contact-form" (submit)="sendEmail($event)">
          <label class="block">
            <span class="text-gray-700">Your name:</span>
            <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
          </label>
          <!-- Email Input -->
     
          <!-- Phone Number Input -->
          <label class="block">
            <span class="text-gray-700">Phone number:</span>
            <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="(909) 456-7890">
          </label>

          <label class="block">
            <span class="text-gray-700">Prefer Contact Method:</span>
            <select name="contact-preference" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <option value="text">Text</option>
              <option value="call">Call</option>
            </select>
          </label>

          <!-- Message Input -->
          <label class="block">
            <span class="text-gray-700">Your message:</span>
            <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
          </label>

          <label class="hidden">
            <input name="location" value="Redlands Office" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
          </label>

          <label class="hidden">
            <input name="page" value="dental-veneers" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
          </label>

          <!-- Submit Button -->
          <button type="submit" value="Send"  class="inline-flex justify-center py-2 mt-6 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Send
          </button>
        </form>

      </div>

    </div>

  </div>

</section>
