import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';


@Component({
  selector: 'app-spanishad1',
  standalone: true,
  imports: [RouterLink,CommonModule],
  templateUrl: './spanishad1.component.html',
  styleUrl: './spanishad1.component.scss'
})
export class Spanishad1Component {


  constructor(private router: Router){}
 
  ngOnInit(): void {}
  formSubmitted = false;

  public sendEmail(e: Event) {
    
    
    e.preventDefault();

    emailjs
      .sendForm('service_3f8eorn', 'template_k9ycz4t', e.target as HTMLFormElement, {
        publicKey: 'QZxS7pV4s_s8ZpGZW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          this.router.navigate(['/thankyou'])



        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
        
      );
      this.formSubmitted = true;
     
      
  }
}
