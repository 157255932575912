import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-crowns',
  standalone: true,
  imports: [CommonModule,RouterLink],
  providers: [],
  templateUrl: './crowns.component.html',
  styleUrl: './crowns.component.scss'
})
export class CrownsComponent {

  title = 'Crowns Specialist in Redlands';


  constructor(
    private router: Router,

    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Trusted Crowns Specialist serving Redlands, CA. Contact us at (909) 793-1595 or visit us at 400 W Stuart Ave #110, Redlands, CA 92374: Ketcherside Orthodontics.' }
    );
  }
  accordionItems = [
    {
      question: 'What are dental crowns?',
      answer: 'Dental crowns are custom-made restorations that fit over the entire part of a tooth that lies above the gum line. They are used to restore the tooth’s shape, size, strength, and improve its appearance. Crowns can be made from various materials, including porcelain, ceramic, gold, and metal alloys.',
      open: false,
    },
    {
      question: 'Is getting a dental crown a painful process?',
      answer: 'The process of getting a dental crown typically involves very little pain, as it is performed under local anesthesia. You may experience some sensitivity and discomfort after the procedure, but this is usually manageable with over-the-counter pain relievers.',
      open: false,
    },
    {
      question: 'How long do dental crowns last?',
      answer: 'With proper care and maintenance, dental crowns can last anywhere from 5 to 15 years or more. The lifespan of a crown depends on the amount of "wear and tear" the crown is exposed to, how well you follow good oral hygiene practices, and your personal mouth-related habits.',
      open: false,
    },
    {
      question: 'Can dental crowns be completed in one visit?',
      answer: 'Some types of dental crowns can be completed in a single visit using CEREC technology or similar systems. Traditional crowns typically require at least two visits – one to prepare the tooth and place a temporary crown, and a second to place the permanent crown.',
      open: false,
    },
    {
      question: 'How do I care for my dental crown?',
      answer: 'Care for a dental crown is similar to care for your natural teeth. This includes brushing twice a day, flossing daily, and regular dental checkups. Avoid chewing hard foods, ice, or other hard objects, especially if you have a porcelain crown.',
      open: false,
    },
    {
      question: 'Are dental crowns covered by insurance?',
      answer: "Most dental insurance plans partially cover the cost of dental crowns, especially when the crown is needed for restorative purposes. However, if the crown is used purely for cosmetic reasons, it may not be covered. You should check your specific dental insurance policy for details about coverage. It's always recommended to consult with your insurance provider and dental office to understand the coverage and any potential out-of-pocket costs.",
      open: false,
    },
    {
      question: 'Can the color of dental crowns be matched to my natural teeth?',
      answer: 'Yes, dental crowns are made to match the color, size, and shape of your natural teeth. Porcelain and ceramic crowns, in particular, can be closely matched to the color of your existing teeth. This ensures that the crowns look natural and blend in seamlessly with your smile.',
      open: false,
    },
  ];


  toggleItem(item: any): void {
    item.open = !item.open;
  }


 
  formSubmitted = false;

  public sendEmail(e: Event) {
    
    e.preventDefault();

    emailjs
      .sendForm('service_3f8eorn', 'template_k9ycz4t', e.target as HTMLFormElement, {
        publicKey: 'QZxS7pV4s_s8ZpGZW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          this.router.navigate(['/thankyou'])


        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
        
      );
      this.formSubmitted = true;
     
      
  }
}
