
import { RouterLink } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-clearbraces',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './clearbraces.component.html',
  styleUrl: './clearbraces.component.scss'
})
export class ClearbracesComponent {

  title = 'Clear Braces in Redlands, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Call us today for a free clear braces consultation! Packing House Dental and Orthodontics helps kids, teens, and adults to straighten their smile with Clear Braces.' }
    );
  }

}
