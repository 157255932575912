import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-ortholp2',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ortholp2.component.html',
  styleUrl: './ortholp2.component.scss'
})
export class Ortholp2Component {
  


  accordionItems = [
    {
      question: 'Do braces hurt?',
      answer: 'It\'s common to experience some discomfort after braces are first placed and following adjustments. The pain is usually mild and can be managed with over-the-counter pain relievers. It generally subsides within a few days.',
      open: false,
    },
    {
      question: 'Are there food restrictions with braces?',
      answer: 'Yes, with braces you should avoid hard, sticky, and chewy foods that can damage the brackets and wires. Examples include popcorn, nuts, hard candies, gum, and caramel. It’s also wise to cut down on sugary foods to prevent cavities.',
      open: false,
    },
    {
      question: 'How long does orthodontic treatment take?',
      answer: 'The duration of treatment varies depending on the complexity of the correction needed. Typically, braces or Invisalign treatment can last anywhere from 6 months to 3 years, with most treatments averaging about 2 years.',
      open: false,
    },
    {
      question: 'Is it necessary to wear retainers after treatment?',
      answer: 'Yes, retainers are essential to maintain the results achieved by orthodontic treatment. They help keep teeth stable in their new positions. Your orthodontist will recommend how long to wear them, which could be indefinitely but often with decreasing frequency over time.',
      open: false,
    },
    {
      question: 'What are my options for retainers?',
      answer: 'There are mainly two types: removable and fixed. Removable retainers are typically made of clear plastic or wire and acrylic and can be taken out for eating and cleaning. Fixed retainers consist of a wire permanently bonded behind the teeth.',
      open: false,
    },
    {
      question: 'What is the difference between braces and Invisalign?',
      answer: 'Braces consist of metal or ceramic brackets attached to the teeth connected by wires to gradually move teeth. Invisalign uses a series of clear, removable aligners that are nearly invisible, offering aesthetic and practical food intake benefits.',
      open: false,
    },
    {
      question: 'How often do I need to visit the orthodontist during treatment?',
      answer: 'Visits are usually scheduled every 4 to 6 weeks to adjust braces and monitor progress or to provide new sets of Invisalign aligners. These regular check-ups are crucial for ensuring the treatment is progressing as planned.',
      open: false,
    },
    {
      question: 'Can adults benefit from orthodontic treatments?',
      answer: 'Absolutely! There is no age limit for orthodontic treatments like braces or Invisalign. Adults can benefit from these to improve their dental health and aesthetics. Treatments might take slightly longer in adults compared to children and teenagers due to the maturity of bone structure.',
      open: false,
    },
    {
      question: 'What are the main benefits of wearing Invisalign over traditional braces?',
      answer: 'Invisalign aligners are nearly invisible, which makes them less noticeable than traditional metal braces. They are also removable, making it easier to eat, drink, brush, and floss. However, they require discipline to wear the aligners for the recommended 22 hours a day.',
      open: false,
    }
  ];
  
  
  
  toggleItem(item: any): void {
    item.open = !item.open;
  }
  

  showDropdown = false;

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  showDropdown2 = false;

  toggleDropdown2() {
    this.showDropdown2 = !this.showDropdown2;
  }

  constructor(private router: Router){}
 
  ngOnInit(): void {}
  formSubmitted = false;

 

  public sendEmail(e: Event) {
    
    
    e.preventDefault();

    emailjs
      .sendForm('service_3f8eorn', 'template_k9ycz4t', e.target as HTMLFormElement, {
        publicKey: 'QZxS7pV4s_s8ZpGZW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          this.router.navigate(['/thankyou'])



        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
        
      );
      this.formSubmitted = true;
     
      
  }
}
