<section class='bg-gradient-to-b from-purple-50 to-cyan-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-cyan-900 bg-cyan-200 rounded-full shadow-md -rotate-1'>
          Restorative Excellence
        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-cyan-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
         Dental Crowns in Redlands
      </h1>
      <h2 class="text-2xl text-cyan-900 font-semibold">What are Dental Crowns?</h2>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-cyan-800 lg:text-left'>
        Dental crowns are custom-fitted tooth prosthetics that are used to replace or restore damaged or missing teeth. Crowns, also known as caps, are fitted over your existing tooth to protect and preserve its structure.
      </p>
      <h2 class="text-2xl font-semibold mt-4 text-cyan-900">Benefits of Dental Crowns</h2>
        <ul>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Restores the appearance and function of your teeth</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Offers a long-term solution with durability</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Custom-made to fit your smile perfectly</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Protects the tooth from further decay or damage</span>
        </li>

      </ul>

    </div>
    <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/crown.webp" alt="Durable Dental Crowns">
      </div>
    </div>
  </div>
</section>

<section class='bg-cyan-100 px-4 py-16 sm:px-6 lg:px-8 z-auto'>
  <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>

    <h1 class='max-w-4xl mx-auto pb-7 text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
      Q&A
    </h1>
  </div>
  <div class="accordion max-w-2xl mx-auto">
    <div *ngFor="let item of accordionItems" class="mb-4">
      <div class="flex items-center justify-between bg-yellow-100 py-8 p-2 cursor-pointer rounded-lg text-xl mx-auto " (click)="toggleItem(item)">
        <span class="mx-auto">{{ item.question }}</span>
        <svg *ngIf="item.open" class="h-4 w-4 fill-current text-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M19 9l-7 7-7-7" />
        </svg>
        <svg *ngIf="!item.open" class="h-4 w-4 bg-yellow-100 text-lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M12 21l-7-7 1.41-1.41L12 18.17l5.59-5.58L19 14z" />
        </svg>

      </div>
      <div *ngIf="item.open" class="p-4 mx-auto item-center text-lg">
        <p>{{ item.answer }}</p>
      </div>
    </div>
  </div>

</section>




<section loading="lazy" class='px-4 pb-12 overflow-hidden bg-purple-100 lg:pt-24 sm:px-6 lg:px-8'>
  <div class='max-w-xl mx-auto lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-32 lg:max-w-screen-xl '>
    <div class='py-16 lg:py-32'>
      <div>
      </div>
      <h1 class='max-w-md mt-4 text-purple-900 h1'>
        Get A Consultation
      </h1>
      <p class='max-w-lg mt-3 text-xl leading-relaxed text-purple-800'>
        Are you in need of Crowns or a dental consulation? Look no further! Our dental clinic is now offering consultation services to address all your dental concerns. Fill out the form and we'll contact you as soon as possible.
      </p>
    </div>
    <div class='relative'>
      <div
        class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
        <div>
          <h3 class='text-2xl font-bold text-purple-900'>
            Contact Form
          </h3>
        </div>
        <div class="pt-7"></div>
        <form class="contact-form" (submit)="sendEmail($event)">
          <label class="block">
            <span class="text-gray-700">Your name:</span>
            <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
          </label>
          <!-- Email Input -->
     
          <!-- Phone Number Input -->
          <label class="block">
            <span class="text-gray-700">Phone number:</span>
            <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="(909) 456-7890">
          </label>

          <label class="block">
            <span class="text-gray-700">Prefer Contact Method:</span>
            <select name="contact-preference" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <option value="text">Text</option>
              <option value="call">Call</option>
            </select>
          </label>

          <!-- Message Input -->
          <label class="block">
            <span class="text-gray-700">Your message:</span>
            <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
          </label>

          <label class="hidden">
            <input name="location" value="Redlands Office" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
          </label>

          <label class="hidden">
            <input name="page" value="dental-crowns" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
          </label>

          <!-- Submit Button -->
          <button type="submit" value="Send"  class="inline-flex justify-center py-2 mt-6 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Send
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
