<section style="display: flex; justify-content: center; align-items: center;" class="py-16">
    <div class="overflow-hidden rounded-lg bg-white shadow" style="max-width: 600px;"> <!-- Adjust max-width as needed -->
      <div class="px-4 py-5 sm:p-6">
        <section class="p-4">
          <h2 class="text-lg font-semibold text-indigo-600">Thank You!</h2>
          <p class="text-gray-700 mt-2">
            Your submission has been received. We will contact you soon.
          </p>
          <h2 class="text-lg font-semibold text-indigo-600 mt-8"><a href="/">Return to Home Page</a></h2>

        </section>
      </div>
    </div>
  </section>
  