import {DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-fillings',
  standalone: true,
  imports: [RouterLink],
  providers: [],
  templateUrl: './fillings.component.html',
  styleUrl: './fillings.component.scss'
})
export class FillingsComponent {



  title = 'Fillings | Redlands, CA';


  constructor(
    private router: Router,

    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Looking for high-quality dental fillings in Redlands? Visit Packing House Dental & Orthodontics for reliable and affordable tooth restoration. (909) 793-1595.' }
    );
  }

 
  formSubmitted = false;

  public sendEmail(e: Event) {
    
    e.preventDefault();

    emailjs
      .sendForm('service_3f8eorn', 'template_k9ycz4t', e.target as HTMLFormElement, {
        publicKey: 'QZxS7pV4s_s8ZpGZW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          this.router.navigate(['/thankyou'])


        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
        
      );
      this.formSubmitted = true;
     
      
  }
}
