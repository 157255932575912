import {  DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-rootcanals',
  standalone: true,
  imports: [RouterLink],
  providers: [],
  templateUrl: './rootcanals.component.html',
  styleUrl: './rootcanals.component.scss'
})
export class RootcanalsComponent {

  title = 'Root Canals in Redlands';


  constructor(
    private router: Router,
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Relieve your tooth pain with Root Canal Treatment at Packing House Dental & Orthodontics. Call (909) 793-1595' }
    );
  }

  formSubmitted = false;

  public sendEmail(e: Event) {
    
    e.preventDefault();

    emailjs
      .sendForm('service_3f8eorn', 'template_k9ycz4t', e.target as HTMLFormElement, {
        publicKey: 'QZxS7pV4s_s8ZpGZW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          this.router.navigate(['/thankyou'])


        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
        
      );
      this.formSubmitted = true;
     
      
  }
}
