import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-invisalign',
  standalone: true,
  imports: [RouterLink],
  providers: [],
  templateUrl: './invisalign.component.html',
  styleUrl: './invisalign.component.scss'
})
export class InvisalignComponent {


  title = 'Invisalign in Redlands, CA';


  constructor(
    private router: Router,
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Invisalign specialists in Redlands, CA. Schedule your free consultation today!' }
    );

    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `{
          "@context": "http://schema.org",
          "@type": "Service",
          "serviceType": "Orthodontic Treatment",
          "name": "Invisalign",
          "description": "Invisalign offers a discreet, comfortable, and effective way to straighten your teeth using custom-made, clear aligners. Ideal for adults and teens seeking a more aesthetic alternative to traditional braces.",
          "provider": {
            "@type": "LocalBusiness",
            "name": "Packing House Dental & Orthodontics",
            "areaServed": {
              "@type": "City",
              "name": "Redlands"
            },
            "category": "Orthodontics",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "400 W Stuart Ave #110",
              "addressLocality": "Redlands",
              "addressRegion": "CA",
              "postalCode": "92374"
            },
            "telephone": "+19097931595",
            "email": "drgaryk1@gmail.com",
            "image": "https://packinghousedentalredlands.com/assets/images1/logo1.svg"
          },
          "areaServed": {
            "@type": "Place",
            "name": "Redlands, CA"
          },
          "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Invisalign Treatment Options",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Invisalign Full Treatment"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Invisalign Teen Treatment"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Invisalign Express Treatment"
                }
              }
            ]
          },
          "openingHours": "Mo-Fr 9:00-17:00",
          "paymentAccepted": "Cash, Credit Card",
          "priceRange": "$$"
        }

        `;

        this._renderer2.appendChild(this._document.body, script);

  }
  formSubmitted = false;

  public sendEmail(e: Event) {
    
    e.preventDefault();

    emailjs
      .sendForm('service_3f8eorn', 'template_k9ycz4t', e.target as HTMLFormElement, {
        publicKey: 'QZxS7pV4s_s8ZpGZW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          this.router.navigate(['/thankyou'])


        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
        
      );
      this.formSubmitted = true;
     
      
  }
}