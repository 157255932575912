import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-braces',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './braces.component.html',
  styleUrl: './braces.component.scss'
})
export class BracesComponent {

  title = 'Braces in Redlands, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }




  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Free braces consultation! Packing House Dental and Orthodontics helps kids, teens, and adults to straighten their smile through Invisalign and Braces.' }
    );


  let script = this._renderer2.createElement('script');
  script.type = `application/ld+json`;
  script.text = `
  {
    "@context": "http://schema.org",
    "@type": "Service",
    "serviceType": "Orthodontist",
    "provider": {
      "@type": "LocalBusiness",
      "name": "Packing House Dental and Orthodontics",
      "telephone": "+19097931595",
      "email": "drgaryk1@gmail.com",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Redlands",
        "addressRegion": "CA",
        "postalCode": "92374",
        "streetAddress": "400 W Stuart Ave"
      },
      "openingHours": "Mo-Fr 9:00-17:00",
      "paymentAccepted": "Cash, Credit Card",
      "priceRange": "$$$",
      "image": "https://packinghousedentalredlands.com/assets/images1/logo1.svg"
    },
    "areaServed": {
      "@type": "City",
      "name": "Redlands"
    },
    "category": [
      "Orthodontics",
      "Braces",
      "Invisalign"
    ],
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Orthodontic Treatments",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Braces"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Clear Braces"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Invisalign"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Aligners"
          }
        }
      ]
    }
  }
  `;

  this._renderer2.appendChild(this._document.body, script);

}
}