import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import emailjs, { type EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-veneers',
  standalone: true,
  imports: [RouterLink],
  providers: [],
  templateUrl: './veneers.component.html',
  styleUrl: './veneers.component.scss'
})
export class VeneersComponent {


  title = 'Veneers Specialist | Redlands';


  constructor(
    private router: Router,
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: "Transform your smile with custom-made veneers in Redlands. Our dental experts will help you enhance your teeth's appearance. Call (909) 793-1595 | Packing House Dental & Orthodontics" }
    );
  }


  formSubmitted = false;

  public sendEmail(e: Event) {
    
    e.preventDefault();

    emailjs
      .sendForm('service_3f8eorn', 'template_k9ycz4t', e.target as HTMLFormElement, {
        publicKey: 'QZxS7pV4s_s8ZpGZW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          this.router.navigate(['/thankyou'])



        },
        (error) => {
          console.log('FAILED...', (error as EmailJSResponseStatus).text);
        },
        
      );
      this.formSubmitted = true;
     
      
  }
}
