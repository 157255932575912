





<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>


  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>

    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          A Confident Smile

        </span>
      </div>
      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Dental Implants in Redlands, CA
      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>






        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">             Natural-looking smile that shines in every situation
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Helps prevent or slow bone loss in your jaw
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Implants help your ability to chew
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">          Obtain a clear estimate of the costs of your treatment
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Explore payment plans that fit your budget
          </span>
        </li>


      </p>

      <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>


        <p class="hidden mx-auto text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mt-14">
          Over <span class='font-semibold text-purple-600'>25 years</span> of experience <br>
        </p>


      </div>


    </div>


 <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" height="396" width="600"  src="../../../../../../assets/images1/landing_1.webp" alt="Beautiful Smile">

      </div>
    </div>


  </div>


  <div class='flex flex-col items-center mt-20 lg:mt-24  lg:flex '>

    <p class="text-xl  tracking-wider text-purple-900 uppercase">
      Rated 5 stars by over <span class='font-semibold text-purple-600'>100 patients</span>
    </p>
    <div class='flex flex-col overflow-hidden  sm:flex-row flex-start'>

      <div class='flex flex-col items-center pt-5 '>
        <div class='flex justify-center w-full'>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        </div>
        <p class='text-lg  tracking-wide text-purple-700 uppercase'>
          Google Reviews
        </p>

        <div class='mt-10 font-semibold lg:mt-12'>
          <a href="tel:909-341-1678" class='mt-6 text-xl font-medium text-black bg-red-500 sm:mt-0  relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
            Call Now



          </a>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="mx-auto bg-gradient-to-b from-purple-100 to-yellow-50 pt-10 pl-10">

  <ul class="flex mx-auto justify-center flex-wrap text-lg text-white font-medium text-center ">
    <li class="mr-2">
        <button (click)="toggleShow()" class="inline-block px-4 py-3 text-white bg-purple-900 rounded-lg active" [ngClass]="{'bg-purple-200 text-black': isShown==false,'text-white bg-purple-900': isShown==true}"
         aria-current="page">Benefits</button>
    </li>
    <li class="mr-2">
      <button (click)="toggleShow1()" class="inline-block px-4 py-3 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown1==true}"
      aria-current="page">Procedure</button>
  </li>
    <li class="mr-2">
        <button (click)="toggleShow2()" class="inline-block px-4 py-3 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown2==true}"
        aria-current="page">Pricing</button>
    </li>
    <li class="mr-2">
        <button (click)="toggleShow3()" class="inline-block px-4 py-3 bg-purple-200 text-black rounded-lg hover:bg-purple-900 hover:text-white" [ngClass]="{' text-white bg-purple-900': isShown3==true}">
          Financing</button>
    </li>


</ul>







</section>


<section [hidden]="!isShown" class='bg-yellow-50 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>


  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-6 lg:gap-8 z-auto'>

    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>

      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Benefits of Dental Implants
      </h1>
      <p class='max-w-2xl mt-3 text-xl mx-auto leading-loose text-center text-purple-800 align-middle justify-center'>
        <li class="flex items-center  my-4">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">             Preserve the natural bone in the mouth, and help to stimulate new bone growth
          </span>
        </li>
        <li class="flex items-center  my-4">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Implants are durable, and provide long term value financially compared to other restorative solutions
          </span>
        </li>
        <li class="flex items-center  my-4">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Dental implants are permanent, and do not slip or click like other solutions
          </span>
        </li>
        <li class="flex items-center  my-4">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">          Implants are comfortable, and look and feel natural in the mouth
          </span>
        </li>
        <li class="flex items-center  my-4">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Implants can last upwards of 25 years, or a lifetime if they are cared for properly
          </span>
        </li>
        <li class="flex items-center  my-4">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Dental Implants restore full chewing power, and are as strong as natural teeth.
          </span>
        </li>
      </p>



    </div>





  </div>
</section>


<section [hidden]="!isShown1" class='bg-yellow-50 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>


  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>

    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>

      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Dental Implant Process

      </h1>
      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>
        The dental implants process requires multiple appointments in our office. We require a consultation with each patient to discuss any goals, and to determine the best treatment plan. Once we are ready to begin the implant process, the first step involves placing the implant into the jawbone. After this step is complete there is a healing process that must occur.
      </p>

      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>
        The healing period allows the implant time to fuse to the jawbone through a process called osseointegration. This allows the implant to function like a natural tooth root in the mouth. In order for the implant to remain sturdy, and provide the required support, the patient must have enough bone present in the jaw. If necessary, bone grafts can be done to provide the additional support that is needed.
          </p>

      <p class='max-w-2xl mt-3 text-xl leading-loose text-center text-purple-800 lg:text-left'>
        Once the healing process is complete, the patient returns to our office to complete the implant process. An abutment or connector is placed on top of the implant, and this holds the crown securely in place. Custom crowns are made to match the shape, size, and color of your natural teeth. Temporary crowns may be necessary until patients return to have the permanent crown installed.
        </p>



    </div>


 <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" height="396" width="600"  src="../../../../../../assets/images1/dentalimplant1.webp" alt="Dental Impalnts">

      </div>
    </div>


  </div>
</section>

<section [hidden]="!isShown2"  class='bg-yellow-50  relative w-full px-4 pt-20 pb-20 sm:px-6 xl:px-8'>

  <div  class='max-w-screen-xl mx-auto pb-4'>

    <div class="relative">

      <h2 class="max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold">
       Implants Pricing
      </h2>
      <p class="max-w-3xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800">



      </p>
      </div>
      </div>



  <!-- Container -->
  <div class='max-w-xl mx-auto lg:max-w-screen-xl'>
    <div class='lg:grid lg:grid-cols-2 md:gap-16 lg:gap-0'>



      <div class='grid gap-8 mt-14 px-6 lg:mt-20 sm:grid-cols-2 lg:gap-4 xl:gap-8'>

        <div class="w-full px-6 py-10 bg-purple-25 rounded-xl lg:px-5 xl:px-10 bg-purple-200">
          <div class="relative">
            <div class="relative inline-block w-full text-left">
              <h3 class="relative text-xl font-bold tracking-normal text-purple-900">
                Single Tooth Implant
              </h3>

              <div class='mt-2'>
                <h2 class="text-purple-900 h1">
                  $4,280
                </h2>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-sm font-medium leading-6 text-purple-700 align-top bg-yellow-200 -rotate-1 rounded-xl">
                    per implant
                  </div>
                </div>
                <p class="block w-full mt-6 font-medium text-purple-900">
                </p>
              </div>

              <ul class='mt-4 space-y-2 text-base pb-8'>

                <!-- Feature 1 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    All inclusive
                  </span>
                </li>

                <!-- Feature 2 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span class="ml-2 text-purple-800">
                    Includes implant, abutment and crown
                  </span>
                </li>



              </ul>

              <!-- CTA button -->
              <!-- <a
                href='#'
                class="inline-block mx-auto h-6 px-6 text-md font-medium leading-6 text-purple-700 align-top bg-yellow-200  rounded-xl"
              >
              ~$179 per month



              </a> -->

            </div>
          </div>
        </div>

        <!-- Pricing 2 -->
        <div class="w-full px-6 py-10 bg-yellow-200 lg:-translate-y-20 rounded-xl lg:px-5 xl:px-10">
          <div class="relative">
            <div class="relative inline-block w-full text-left">
              <h3 class="relative text-xl font-bold tracking-normal text-purple-900">
                Two Implants
              </h3>

              <div class='mt-2'>
                <h2 class="text-purple-900 h1">
                  $8,560
                </h2>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-sm font-medium leading-6 text-purple-700 align-top bg-purple-200 -rotate-1 rounded-xl">
                    per implant
                  </div>
                </div>
                <p class="block w-full mt-6 font-medium text-purple-900">
                </p>
              </div>

              <!-- Features -->
              <ul class='mt-4 space-y-2 text-base pb-8'>

                <!-- Feature 1 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>

                  <span class="ml-2 text-purple-800">
                    All inclusive
                  </span>
                </li>

                <!-- Feature 2 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>

                  <span class="ml-2 text-purple-800">
                    Includes implants, abutments and crowns
                  </span>
                </li>



              </ul>

              <!-- CTA button -->
              <!-- <a
              href='#'
              class="inline-block mx-auto h-6 px-3 text-md font-medium leading-6 text-purple-700 align-top bg-purple-200  rounded-xl"
            >
              $448 per month



            </a> -->

            </div>
          </div>
        </div>

      </div>

      <!-- Pricing cards -->
      <div class='grid gap-8 mt-14 px-6 lg:mt-20 sm:grid-cols-2 lg:gap-4 xl:gap-8'>

        <!-- Pricing 1 -->
        <div class="w-full px-6 py-10 bg-purple-25 rounded-xl lg:px-5 xl:px-10 bg-purple-200">
          <div class="relative">
            <div class="relative inline-block w-full text-left">
              <h3 class="relative text-xl font-bold tracking-normal text-purple-900">
                Once Arch
              </h3>

              <div class='mt-2'>
                <h2 class="text-purple-900 h1">
                  $12,390
                </h2>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-sm font-medium leading-6 text-purple-700 align-top bg-yellow-200 -rotate-1 rounded-xl">
                    per arch
                  </div>
                </div>
                <p class="block w-full mt-6 font-medium text-purple-900">
                </p>
              </div>

              <!-- Features -->
              <ul class='mt-4 space-y-2 text-base pb-8'>

                <!-- Feature 1 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>

                  <span class="ml-2 text-purple-800">
                    All inclusive
                  </span>
                </li>

                <!-- Feature 2 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>

                  <span class="ml-2 text-purple-800">
                    Includes implants, abutments and crowns
                  </span>
                </li>



              </ul>

              <!-- CTA button -->
              <!-- <a
                href='#'
                class="inline-block mx-auto h-6 px-6 text-md font-medium leading-6 text-purple-700 align-top bg-yellow-200  rounded-xl"
              >
               $517 per month



              </a> -->

            </div>
          </div>
        </div>

        <!-- Pricing 2 -->
        <div class="w-full px-6 py-10 bg-yellow-200 lg:-translate-y-20 rounded-xl lg:px-5 xl:px-10">
          <div class="relative">
            <div class="relative inline-block w-full text-left">
              <h3 class="relative text-xl font-bold tracking-normal text-purple-900">
                Full Set
              </h3>

              <div class='mt-2'>
                <h2 class="text-purple-900 h1">
                  $32,280
                </h2>
                <div class='mt-3'>
                  <div class="inline-block h-6 px-3 text-sm font-medium leading-6 text-purple-700 align-top bg-purple-200 -rotate-1 rounded-xl">
                    per 2 Arches
                  </div>
                </div>
                <p class="block w-full mt-6 font-medium text-purple-900">
                </p>
              </div>

              <!-- Features -->
              <ul class='mt-4 space-y-2 text-base pb-8'>

                <!-- Feature 1 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>

                  <span class="ml-2 text-purple-800">
                    All inclusive
                  </span>
                </li>

                <!-- Feature 2 -->
                <li class='flex items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>

                  <span class="ml-2 text-purple-800">
                    Includes implant, abutment and crown
                  </span>
                </li>



              </ul>

              <!-- CTA button -->
              <!-- <a
              href='#'
              class="inline-block mx-auto h-6 px-3 text-md font-medium leading-6 text-purple-700 align-top bg-purple-200  rounded-xl"
            >
              $1,345 per month



            </a> -->

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>








</section>

<section [hidden]="!isShown3" class='bg-yellow-50  relative w-full px-4 pt-4 pb-20 sm:px-6 xl:px-8'>

  <div class='max-w-xl mx-auto mt-16 lg:max-w-none sm:mt-20 lg:mt-24 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24'>
    <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-7 lg:row-start-1'>
      <div class="mx-auto pb-7 text-center mt-10">

      </div>
      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Insurance
      </h1>
        <p class='max-w-2xl mt-3 text-xl text-center mx-auto leading-loose text-purple-800 sm:mt-4'>
          We take all PPO dental insurance and medi-cal. Some insurances will cover some of the cost of dental implants. When you come in for your comprehensive dental exam we will check with your insurance and let you know how much they will cover.
        </p>

    </div>
    <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-1 lg:row-start-1'>
      <div class="mx-auto pb-7 text-center mt-10">

      </div>
      <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Third Party Financing
      </h1>
        <p class='max-w-2xl mt-3 text-xl text-center mx-auto leading-loose text-purple-800 sm:mt-4'>
          We understand how important it is to be able to restore your dental health and your confidence with a long-term solution. That’s why we offer third-party financing options and work with you on payment plans to fit your budget. At your consultation, you’ll review all the options available to you.
        </p>

    </div>




  </div>
</section>





<section class="bg-gradient-to-b from-yellow-50 to-purple-50 h-auto pb-20 pt-20">

  <div class='max-w-screen-xl mx-auto'>

    <div class="relative">

      <h2 class="max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold">
       How it works
      </h2>


    </div>

    <div class='max-w-xl mx-auto mt-16 lg:max-w-none sm:mt-20 lg:mt-24 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24'>
      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-7 lg:row-start-1'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Initial Visit
          </span>
        </div>
        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          1. Comprehensive Dental Exam
        </h3>
        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
          Your first visit will consist of diagnostic records, an exam, a treatment plan tailored to your teeth, and financing options. Each patient’s implant treatment is different so we leave ample time for questions.
      </p>

        <div class='mt-6'>
        </div>
      </div>
      <div class='relative lg:col-span-6 lg:col-start-1 lg:row-start-1'>
        <div class='w-full rounded-3xl px-10 lg:px-0'>
          <div class="aspect-w-2 aspect-h-3 rounded-2xl pt-16">
            <img class="object-cover rounded-2xl" loading="lazy" src="../../../../../../assets/images1/consult_2.webp" alt="Invisalign Consultation">
          </div>

        </div>
      </div>



    </div>

    <div class='max-w-xl mx-auto mt-20 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>



      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Quick and Easy
          </span>
        </div>


        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          2. Treatment
        </h3>



        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>


            Next your dentist will place the dental implant. The doctor will prepare the area and place a specialalized metal posts where your new teeth will attach.



      </p>

        <div class='mt-6'>

        </div>
      </div>
      <div class='relative lg:order-2 lg:col-span-6'>
        <div class='relative '>
          <div class="w-full rounded-3xl px-10 lg:px-0">
            <img class="object-cover rounded-3xl "  loading="lazy" src="../../../../../../assets/images1/dentalimplants.webp" alt="girl with beautiful smile">
          </div>

        </div>
      </div>

    </div>

    <div class='max-w-xl mx-auto mt-20 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>
      <div class='flex flex-col justify-center mt-16 sm:mt-44 lg:mt-0 lg:col-span-6 lg:col-start-7 lg:row-start-1'>
        <div class="mx-auto pb-7 ">
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Results
          </span>
        </div>
        <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          3. Smile
        </h3>
        <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
            Once you've finished your treatment show off your new smile!
      </p>

        <div class='mt-6'>
        </div>
      </div>
      <div class='relative lg:order-2 lg:col-span-6 lg:col-start-1 lg:row-start-1'>
        <div class='w-full rounded-3xl px-10 lg:px-0'>
          <div class="aspect-w-3 aspect-h-4 rounded-3xl">
            <img class="object-cover rounded-3xl"  loading="lazy" src="../../../../../../assets/images1/smile_1.webp" alt="girl with beautiful smile">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>








<section loading="lazy" class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' width="300" height="128"loading="lazy" src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Packing House Dental & Orthodontics</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="underline">
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9093286376" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call For A Consultation

        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 " width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" alt="image of phone">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>
