<!-- <section class="">
    <video autoplay muted loop src="../../../../assets/images1/AdobeStock_550833618.mov" style="height: 100%;" class="absolute -z-10 h-full object-cover "></video>
    <app-navbar2 class=""></app-navbar2>

  <div class="h-full relative"style="height: 100vh;">
      
    <div class="mx-auto max-w-7xl mt-96 px-6 lg:px-8">
      <div class=" mx-auto max-w-2xl text-center ">
        <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Free Orthodontic Consultation</h1>
        <p class="bg-slate-100 bg-opacity-30 border-slate-500 mt-6 text-lg leading-8 text-gray-950">Discover how affordable your perfect smile can be. Schedule your free consultation today to learn about different treatment options and pricing.</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a href="tel:9093411678" class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Call to Schedule</a>
          <a href="sms:9096544384"class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Text to schedule</a>
        </div>
      </div>
    </div>
    
  </div>


</section>
 -->
 


<div class="bg-gray-700 -z-20">

  <div class="relative isolate pt-14">
    <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
      <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
    </div>
    <a href="/" class="ml-10 font-semibold text-xl leading-7 text-[#B5CCFF]">Packing House Dental & Orthodontics</a>
    <div class="py-24 sm:py-32 lg:pb-40">
      
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
       
        <div class="mx-auto max-w-2xl text-center">
          <h1 class="text-5xl font-bold tracking-tight text-white sm:text-6xl">Free Orthodontic Consultation</h1>
          <h2 class="text-2xl py-2 font-bold tracking-tight  text-[#B5CCFF] ">Located in Redlands, CA</h2>
          <p class="mt-6 text-xl leading-8 text-gray-100">Discover how affordable your perfect smile can be, schedule your free consultation today.</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="tel:9093411678" class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Call to Schedule</a>
            <a href="sms:9096544384"class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Text to schedule</a>
          </div>
        </div>
        <img src="../../../../assets/images1/lphero1.jpeg" alt="App screenshot" width="400" height="400" class="lg:hidden xl:hidden mt-16 mx-auto rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 sm:mt-24">
      </div>
    </div>
     <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
      <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
    </div> 
  </div>



<div class="relative isolate overflow-hidden px-6 py-5  lg:overflow-visible lg:px-0">
  
  <div class="absolute inset-0 -z-10 ">
    <svg class="absolute  top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-400 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
      <defs>
        <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
     
    </svg>
  </div>
  </div>
  <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
    <div class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
     
    </div>
    <div class=" -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
      <img class="w-[48rem] mx-auto rounded-xl bg-gray-900 shadow-2xl  ring-yellow-400" src="../../../../assets/images1/lphero2.jpeg" alt="">
    </div>
    <div class="mb-28 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 z-10">
      <div class="lg:pr-4">
        <div class="max-w-xl mx-5 text-base leading-7 text-gray-200 lg:max-w-lg">
            <ul role="list" class="space-y-8 text-gray-100 text-xl">
              <h1 class="mt-2 text-3xl font-bold tracking-normal text-gray-100 ">Pricing</h1>
              <li class="flex  gap-x-3 pl-6">
            
                <span><strong class="font-semibold text-gray-100"></strong> 
                 Braces are one of the most affordable treatments, generally our prices range from $2700 to $4500 but with our payment plans you can finance with 0% interest over the length of your treatment making your payments low and affordable.
                </span>
              </li>
              <li class="flex gap-x-3 pl-6">
          
              
                <span><strong class="font-semibold text-gray-100"></strong> 
                  
                Invisalign is on average $2000 more than braces it is the best option when you need a discrete orthodontic solution. But you need to consistently wear them 22 hours per day.
                </span>
              </li>
              
               <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-100 ">Financing & Payment plans</h1>
              <li class="flex gap-x-3 pl-6">
             
                <span><strong class="font-semibold text-gray-100">Financing.</strong> 
                  We offer 0% interest in-house financing for our orthodontic treatments with payments as low as $112/month. For even more affordable payments we also accept carecredit which allows you to spreadout payments to over 48 months.
                </span>
              </li>
              <li class="flex gap-x-3 pl-6">
           
                <span><strong class="font-semibold text-gray-200">Insurance.</strong> 
                  We accept all PPO dental insurances and medi-cal. Some insurances will help pay a portion of your treatment, schedule a free consultation today and we can tell you how much your insurance will cover.
                </span>
              </li>
              <!-- <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-200">Invisalign vs Braces.</strong> 
                Invisalign is best when your number 1 priority is a discrete treatment. Braces are the better for more complex treatments when your teeth need to be rotated into their correct position.
                </span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-200">Pricing.</strong> 
                Braces are the most affordable option on average it is $2000 cheaper than invisalign. Invisalign pricing is dependant upon the length of your treatment. 
                </span>
              </li>
            <li class="flex gap-x-3">
              <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
              </svg>
              <span><strong class="font-semibold text-gray-200">Cost of Orthodontics.</strong> 
                The cost of orthodontic treatment varies for each patient, as it depends on how long you'll need to wear braces and other specific needs. We invite you to come in for a free consultation to get an exact quote for your treatment.</span>
            </li>
            <li class="flex gap-x-3">
              <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
              </svg>
              <span><strong class="font-semibold text-gray-200">Payment Plans.</strong> We offer flexible payment plans to help manage the cost of your treatment, making it easier to budget for your and your family's orthodontic care. Additionally, we accept CareCredit, allowing you more options to finance your treatment.</span>
            </li>
            <li class="flex gap-x-3">
              <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
              </svg>
              <span><strong class="font-semibold text-gray-200">Payment Plans.</strong> We offer flexible payment plans to help manage the cost of your treatment, making it easier to budget for your and your family's orthodontic care. Additionally, we accept CareCredit, allowing you more options to finance your treatment.</span>
            </li>
            <li class="flex gap-x-3">
              <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                <path fill-rule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clip-rule="evenodd" />
              </svg>
              <span><strong class="font-semibold text-gray-900">Easy Appointments.</strong> We know your busy so we offer convenient hours, including evenings and some weekends. We want to make sure coming here is simple for you and your family.</span>
            </li> -->
          </ul>
          <!-- <h2 class="mt-16 text-2xl font-bold tracking-tight text-white">Financing.</h2>
          <p class="mt-8 text-lg pl-6">We offer 0% interest in-house financing for our orthodontic treatments with payments as low as $112/month. For even more affordable payments we also accept carecredit which allows you to spreadout payments to over 48 months.</p>
          <h2 class="mt-16 text-2xl font-bold tracking-tight text-white">Insurance.</h2>
          <p class="mt-6 text-lg pl-6">Some insurances will help pay for your orthodontics, we accept all PPO dental insurances and medical. Schedule your free consultation today and we can tell you how much your insurance will cover.</p> -->
        </div>
      </div>
    </div>
  </div>
</div>


<div class="bg-white pb-16 pt-24 sm:pb-24 sm:pt-32 xl:pb-32">
  <div class="bg-gray-700 pb-20 sm:pb-24 xl:pb-0">
    <div class="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
     
        <h2 class="mt-16 text-3xl font-bold mx-auto align-middle justify-center tracking-tight text-white">Reviews.</h2>
     
      <div class="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
        <figure class="relative isolate pt-6 sm:pt-12">
          <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute left-0 top-0 -z-10 h-32 stroke-white/20">
            <path id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
            <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x="86" />
          </svg>
          <blockquote class="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
            <p>"LOVE love love this office. Brandy is a sweetheart and very helpful. Denise is one of the BEST hygienist I've seen to date. Dr. K is my favorite, he's really down to earth and always makes me laugh every visit. Everyone is super friendly and overall this office has a great team. I did my research for different Orthodontist offices in the area and I'm happy I chose this office."</p>
          </blockquote>
          <figcaption class="mt-8 text-base">
            <div class="font-semibold text-white">	
              Dij</div>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>

  
</div>



  <!-- FAQ section -->
  <div class="bg-gray-700">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-4xl divide-y divide-white/10">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
        <dl class="mt-10 space-y-6 divide-y divide-white/10">
          <div class="pt-6">
            <dt>

              <div *ngFor="let item of accordionItems" class="mb-4">
                <div class="flex w-full items-start justify-between text-left text-white" (click)="toggleItem(item)">
                  <span class="text-lg font-semibold leading-7 ">{{ item.question }}</span>
                  <svg *ngIf="item.open" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                   
                  </svg>
                 
                  <svg *ngIf="!item.open"  class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                  </svg>
        
                </div>
                <div *ngIf="item.open" class="p-4 mx-auto text-lg text-white border-2 border-white  rounded-lg">
                  <p>{{ item.answer }}</p>
                </div>
              </div>
              <!-- Expand/collapse question button -->
             
            </dt>
      
          </div>
  
          <!-- More questions... -->
        </dl>
      </div>
    </div>
  </div>

  <section class="bg-slate-700 h-auto pb-10 ">
  
    <div class='flex flex-col justify-center align-middle max-w-3xl mx-auto lg:w-1/2 w-3/4 lg:mt-0 lg:max-w-none lg:col-span-6'>
   
      <div
          class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
          <div>
            <h3 class='text-2xl font-bold text-slate-700'>
              Send us a message
            </h3>
            <p class='text-slate-700 mt-0.5 text-opacity-90'>
              We'll set up a free consultation for you!
            </p>
          </div>
  
          <div class="pt-7">
            </div>
  
  
        <form class="contact-form" (submit)="sendEmail($event)">
              <label class="block">
                <span class="text-gray-700">Your name:</span>
                <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="John Doe">
              </label>
              <!-- Email Input -->
         
              <!-- Phone Number Input -->
              <label class="block">
                <span class="text-gray-700">Phone number:</span>
                <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="(909) 456-7890">
              </label>
  
              <label class="block">
                <span class="text-gray-700">Preferred Contact Method:</span>
                <select name="contact-preference" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                  <option value="call">Call</option>

                  <option value="text">Text</option>
                </select>
              </label>
    
              <!-- Message Input -->
              <label class="block">
                <span class="text-gray-700">Your message:</span>
                <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
              </label>
  
              <label class="hidden">
                <input name="location" value="Redlands Office" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
              </label>
  
              <label class="hidden">
                <input name="page" value="orthobracesadlp2" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
              </label>
    
              <!-- Submit Button -->
              <button type="submit" value="Send"  class="inline-flex justify-center py-2 mt-6 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Send
              </button>
            </form>
            </div>
    </div>
  
  
  </section>
  
  <div class="relative isolate overflow-hidden bg-gray-700">
    <div class="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Packing House Dental & Orthodontics.<br></h2>
        <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Smile Brighter.<br></h2>
        <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">Schedule a free consultation</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a href="tel:9093411678" class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Call to Schedule</a>
            <a href="sms:9096544384"class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Text to schedule</a>
        </div>
      </div>
    </div>
    <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]" aria-hidden="true">
      <circle cx="512" cy="512" r="512" fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fill-opacity="0.7" />
      <defs>
        <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
          <stop stop-color="#7775D6" />
          <stop offset="1" stop-color="#E935C1" />
        </radialGradient>
      </defs>
    </svg>
  </div>
  