<!-- Hero -->
<div class="relative overflow-hidden">
  <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10">
    <div class="max-w-2xl text-center mx-auto">
      <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl dark:text-white">Designed for you to get more <span class="text-blue-600">simple</span></h1>
      <p class="mt-3 text-lg text-gray-800 dark:text-neutral-400">Build your business here. Take it anywhere.</p>
    </div>

    <div class="mt-10 relative max-w-5xl mx-auto">
      <div class="w-full object-cover h-96 sm:h-[480px] bg-[url('https://images.unsplash.com/photo-1606868306217-dbf5046868d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1981&q=80')] bg-no-repeat bg-center bg-cover rounded-xl"></div>

      <div class="absolute inset-0 size-full">
        <div class="flex flex-col justify-center items-center size-full">
          <a class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800" href="#">
            <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="5 3 19 12 5 21 5 3"/></svg>
            Play the overview
          </a>
        </div>
      </div>

      <div class="absolute bottom-12 -start-20 -z-[1] size-48 bg-gradient-to-b from-orange-500 to-white p-px rounded-lg dark:to-neutral-900">
        <div class="bg-white size-48 rounded-lg dark:bg-neutral-900"></div>
      </div>

      <div class="absolute -top-12 -end-20 -z-[1] size-48 bg-gradient-to-t from-blue-600 to-cyan-400 p-px rounded-full">
        <div class="bg-white size-48 rounded-full dark:bg-neutral-900"></div>
      </div>
    </div>
  </div>
</div>
<!-- End Hero -->

<div class="bg-white">
    <!--
      Mobile menu
  
      Off-canvas menu for mobile, show/hide based on off-canvas menu state.
    -->
   
  
    <!-- Hero section -->
    <div class="relative bg-stone-500">
      <!-- Decorative image and overlay -->
      <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
        <video autoplay muted loop src="../../../../assets/images1/AdobeStock_550833618.mov" alt="" class="h-full  opacity-85 w-full object-cover object-center"></video>
      </div>
      <div aria-hidden="true" class="absolute inset-0 bg-gray-900 opacity-50"></div>
  
      <!-- Navigation -->
   
  
      <div class="relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-64 lg:px-0">
        <!-- <h1 class="text-4xl font-bold tracking-tight text-white lg:text-4xl">Braces Sale Event</h1> -->
        <h1 class="text-6xl font-bold tracking-tight text-white lg:text-6xl">Braces for <span class="original-price text-white">$4800</span> $2700</h1>
        <p class="mt-4 text-xl text-white">For the month of May we are offering $2100 off braces. Offer includes free orthodontic consultation, free dental exam, x-rays and clear retainers after treatment.  Affordable Orthodontics Because Everyone Deserves an Amazing Smile</p>
        <div class="mx-auto max-w-2xl text-center mt-6">
            <div class="">
                <div class="text-3xl text-white">Offer Ends May 30th</div>
                <div class="flex gap-6 text-yellow-400">
                  <div class="countdown-item">
                    <span class="countdown-number" #days></span>
                    <span class="countdown-label">Days</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-number" #hours></span>
                    <span class="countdown-label">Hours</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-number" #minutes></span>
                    <span class="countdown-label">Minutes</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-number" #seconds></span>
                    <span class="countdown-label">Seconds</span>
                  </div>
                </div>
              </div>
        </div>
        <a href="#" class="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100">Call to Schedule a Free Consultation</a>
        <a href="#" class="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100">Text to Schedule a Free Consultation</a>
      </div>
     
    </div>
</div>
  

<div class="bg-white -z-20">

    <!-- <div class="relative isolate pt-14">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>
      <a href="/" class="ml-10 font-semibold text-xl leading-7 text-[#0f1a31]">The Brace Connection</a>
      <div class="py-24 sm:py-32 lg:pb-40">
        
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
         
          <div class="mx-auto max-w-2xl text-center">
            <h1 class="text-5xl font-bold tracking-tight text-black sm:text-6xl">
                Braces for only
                <span class="original-price text-black">$4200</span>
                <span class="current-price"> $2700</span>
            </h1>
            <h2 class="text-2xl py-2 font-bold tracking-tight  text-black ">At the Brace Connection we belive everyone deserves a perfect smile thats why we offer orthodontics at an affordable price.</h2>

            <div class="countdown-container">
                <div class="text-5xl">Sale Ends May 16th</div>
                <div class="countdown">
                  <div class="countdown-item">
                    <span class="countdown-number" #days>54</span>
                    <span class="countdown-label">Days</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-number" #hours>16</span>
                    <span class="countdown-label">Hours</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-number" #minutes>45</span>
                    <span class="countdown-label">Minutes</span>
                  </div>
                  <div class="countdown-item">
                    <span class="countdown-number" #seconds>56</span>
                    <span class="countdown-label">Seconds</span>
                  </div>
                </div>
              </div>
              
            <p class="mt-6 text-xl leading-8 text-black">Discover how affordable your perfect smile can be, schedule your free consultation today.</p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <a href="tel:9093411678" class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Call to Schedule</a>
              <a href="sms:9096544384"class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Text to schedule</a>
            </div>
          </div>
          <img src="../../../../assets/images1/lphero1.jpeg" alt="App screenshot" width="400" height="400" class="lg:hidden xl:hidden mt-16 mx-auto rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 sm:mt-24">
        </div>
      </div>
       <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div> 
    </div>

    <h1 class="text-5xl font-bold tracking-tight text-center pb-7 text-black sm:text-6xl">
        Offer Includes
    </h1>
  -->
   
<div class="bg-gray-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-none">
        <div class="text-center">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Offer Includes</h2>
        </div>
    
        
        <dl class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
          <div class="flex flex-col bg-white/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-300"></dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">Orthodontic Consultation</dd>
          </div>
          <div class="flex flex-col bg-white/5 p-8">
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">Dental Exam & X-Rays</dd>
          </div>
          <div class="flex flex-col bg-white/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-300">every 6 months while in braces</dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">Teeth cleanings</dd>
          </div>
       
          <div class="flex flex-col bg-white/5 p-8">
            <dt class="text-sm font-semibold leading-6 text-gray-300"> </dt>
            <dd class="order-first text-3xl font-semibold tracking-tight text-white">Clear Retainers</dd>
          </div>
         
        </dl>
      </div>
    </div>
  </div>
  
  <div class="relative isolate overflow-hidden px-6 py-5  lg:overflow-visible lg:px-0">
    
    <div class="absolute inset-0 -z-10 ">
      <svg class="absolute  top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-400 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
       
      </svg>
    </div>
    </div>
    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      <div class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
       
      </div>
      <div class=" -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
        <img class="w-[48rem] mx-auto rounded-xl bg-gray-900 shadow-2xl  ring-yellow-400" src="../../../../assets/images1/lphero2.jpeg" alt="">
      </div>
      <div class="mb-28 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 z-10">
        <div class="lg:pr-4">
          <div class="max-w-xl mx-5 text-base leading-7 text-gray-200 lg:max-w-lg">
              <ul role="list" class="space-y-8 text-gray-100 text-xl">
                <h1 class="mt-2 text-3xl font-bold tracking-normal text-gray-100 ">Pricing</h1>
                <li class="flex  gap-x-3 pl-6">
              
                  <span><strong class="font-semibold text-gray-100"></strong> 
                   Braces are one of the most affordable treatments, generally our prices range from $2700 to $4500 but with our payment plans you can finance with 0% interest over the length of your treatment making your payments low and affordable.
                  </span>
                </li>
                <li class="flex gap-x-3 pl-6">
            
                
                  <span><strong class="font-semibold text-gray-100"></strong> 
                    
                  Invisalign is on average $2000 more than braces it is the best option when you need a discrete orthodontic solution. But you need to consistently wear them 22 hours per day.
                  </span>
                </li>
                
                 <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-100 ">Financing & Payment plans</h1>
                <li class="flex gap-x-3 pl-6">
               
                  <span><strong class="font-semibold text-gray-100">Financing.</strong> 
                    We offer 0% interest in-house financing for our orthodontic treatments with payments as low as $112/month. For even more affordable payments we also accept carecredit which allows you to spreadout payments to over 48 months.
                  </span>
                </li>
                <li class="flex gap-x-3 pl-6">
             
                  <span><strong class="font-semibold text-gray-200">Insurance.</strong> 
                    We accept all PPO dental insurances and medi-cal. Some insurances will help pay a portion of your treatment, schedule a free consultation today and we can tell you how much your insurance will cover.
                  </span>
                </li>
                <!-- <li class="flex gap-x-3">
                  <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                  </svg>
                  <span><strong class="font-semibold text-gray-200">Invisalign vs Braces.</strong> 
                  Invisalign is best when your number 1 priority is a discrete treatment. Braces are the better for more complex treatments when your teeth need to be rotated into their correct position.
                  </span>
                </li>
                <li class="flex gap-x-3">
                  <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                  </svg>
                  <span><strong class="font-semibold text-gray-200">Pricing.</strong> 
                  Braces are the most affordable option on average it is $2000 cheaper than invisalign. Invisalign pricing is dependant upon the length of your treatment. 
                  </span>
                </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-200">Cost of Orthodontics.</strong> 
                  The cost of orthodontic treatment varies for each patient, as it depends on how long you'll need to wear braces and other specific needs. We invite you to come in for a free consultation to get an exact quote for your treatment.</span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-200">Payment Plans.</strong> We offer flexible payment plans to help manage the cost of your treatment, making it easier to budget for your and your family's orthodontic care. Additionally, we accept CareCredit, allowing you more options to finance your treatment.</span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-200">Payment Plans.</strong> We offer flexible payment plans to help manage the cost of your treatment, making it easier to budget for your and your family's orthodontic care. Additionally, we accept CareCredit, allowing you more options to finance your treatment.</span>
              </li>
              <li class="flex gap-x-3">
                <svg class="mt-1 h-5 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                  <path fill-rule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clip-rule="evenodd" />
                </svg>
                <span><strong class="font-semibold text-gray-900">Easy Appointments.</strong> We know your busy so we offer convenient hours, including evenings and some weekends. We want to make sure coming here is simple for you and your family.</span>
              </li> -->
            </ul>
            <!-- <h2 class="mt-16 text-2xl font-bold tracking-tight text-white">Financing.</h2>
            <p class="mt-8 text-lg pl-6">We offer 0% interest in-house financing for our orthodontic treatments with payments as low as $112/month. For even more affordable payments we also accept carecredit which allows you to spreadout payments to over 48 months.</p>
            <h2 class="mt-16 text-2xl font-bold tracking-tight text-white">Insurance.</h2>
            <p class="mt-6 text-lg pl-6">Some insurances will help pay for your orthodontics, we accept all PPO dental insurances and medical. Schedule your free consultation today and we can tell you how much your insurance will cover.</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  