
<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Free Consultation
        </span>
      </div>
      <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Teen Invisalign in Redlands
      </h1>
      <p class='mt-3 text-xl leading-loose text-purple-800 max-w-2xl text-center lg:text-left'>
        Discover the benefits of Teen Invisalign – a perfect blend of efficiency and invisibility, designed to cater to the unique needs of teenagers.
      </p>
      <ul>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">               Free smile exam to assess your teeth and bite
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Get personalized treatment based on your unique smile
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">          Obtain a clear estimate of the costs of your treatment
          </span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7'  src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">         Explore payment plans that fit your budget
          </span>
        </li>



      </ul>

    </div>


    <div class='flex flex-col justify-center mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6 max-w-3xl'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/teeninvisalign.webp" alt="Teen Invisalign">
      </div>
    </div>
  </div>
  <div class='mt-10 lg:mt-12 text-center mx-auto max-w-xl lg:max-w-screen-xl'>
    <a href="tel:9093411678" class='inline-flex items-center justify-center px-8 py-3 mt-6 text-lg font-medium text-black bg-orange-500 rounded-full hover:bg-yellow-500 hover:text-black duration-300 ease-in-out sm:mt-0 sm:ml-6'>
      Schedule a Free Consultation
    </a>
  </div>
</section>

<section loading="lazy" class='px-4 pb-32 overflow-hidden bg-purple-100 sm:pb-24 sm:px-6 lg:px-8 pt-10'>
  <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
    Benefits of Teen Invisalign in Redlands
  </h1>
  <div class="mt-10 lg:flex justify-center mx-auto">
    <div class='max-w-2xl lg:mr-4'>
      <ul class='list-disc text-xl text-purple-800 mx-auto pl-5'>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Virtually Invisible: Teens can straighten their teeth without feeling self-conscious.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Removable Aligners: Ideal for maintaining oral hygiene and no food restrictions.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">No Interruption to Activities: Perfect for sports and other extracurricular activities without the worry of traditional braces.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Comfortable Fit: Smooth, comfortable aligners suited for active teen lifestyles.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Effective Treatment: Addresses various orthodontic issues with precision.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Boosts Confidence: Improves smiles and self-esteem during crucial years.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Less Orthodontist Visits: Requires fewer check-ups than traditional braces.</span>
        </li>
      </ul>
    </div>
  </div>
  <div class='text-center max-w-xl mx-auto lg:max-w-screen-xl'>
    <p class='mt-10 text-lg text-purple-800'>
      Teen Invisalign in Redlands is the perfect solution for young individuals looking for an effective, discreet, and comfortable way to enhance their smile. Visit Packing House Dental & Orthodontics to start the journey towards a confident and beautiful smile.
    </p>
  </div>
</section>






@defer (prefetch on idle) {


<section class='px-4 -mt-8  bg-gradient-to-b from-purple-100 to-white sm:py-4 sm:mt-0 sm:px-6 lg:px-8'>

  <div class='max-w-3xl mx-auto lg:max-w-screen-xl pb-20'>
    <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1 pb-10">
      Benefits of Invisalign for Teens
    </h2>

    <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

      <div class='flex flex-col justify-center'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
          Perfect for Busy Schedules
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Invisalign for Teens treatment is ideal for busy parents and teens in Redlands and surrounding areas because there are never any emergency appointments from broken wires or brackets.
        </p>
          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

          Invisalign often requires fewer appointments than traditional metal braces because you get your Invisalign aligners in advance and each visit requires less "chair time."
          </p>
          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          The typical Invisalign for Teens patient sees Dr. Ketcherside once every 8 weeks for a total average treatment time of 9 to 15 months.
           </p>

        <div class='grid max-w-4xl gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-1 sm:mx-auto lg:mx-0 lg:max-w-md'>
          <h2 class='text-purple-900 pt-2 sm:text-center lg:text-left h2'>
            Able to Correct Many Cases
          </h2>

          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                crowded teeth
              </h5>

            </div>
          </div>

          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                spaced teeth
              </h5>

            </div>
          </div>


          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                deep bite
              </h5>

            </div>
          </div>


          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                open bite
              </h5>

            </div>
          </div>





        </div>

        <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
          Comparable Cost to Traditional Braces
        </h2>


        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

          Many factors determine the cost of Invisalign treatment, and at Packing House Dental & Orthodontics, we understand that every person’s needs are different. In most cases, the cost of Invisalign is comparable to traditional braces and covered by most dental insurance plans.
        </p>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

          Financing options are also available to make Invisalign more affordable for your family. The Packing House Dental & Orthodontics team is always here to answer any questions regarding financing, payment plans, and insurance claims.
          </p>

      </div>

      <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
        <div class='aspect-w-3 aspect-h-4'>
          <div class='flex flex-col justify-center'>
            <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
              Great for Active Teens in Redlands
            </h2>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              As a teenager, you should be able to participate in the activities you’re interested in while still having the confidence to attend social events and make lasting memories. You should also get efficient orthodontic treatment for crowded teeth, uneven spacing, and bite issues.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              Invisalign for Teens is an ideal choice for teens in Redlands who play sports, play certain types of instruments, love crunchy foods, or are worried about how metal braces will look at homecoming or in the yearbook.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              With the clear, comfortable technology of Invisalign and Dr. Ketcherside expert care, teens can keep being themselves and love their smiles even more!
                 </p>


              <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
                Easier to Keep Clean
              </h2>


              <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

                Aligners with Packing House Dental & Orthodontics are often more hygienic than braces because it is much easier to clean your teeth when there are no braces in the way.
              </p>
              <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

                This also helps your teeth stay clean and white throughout treatment with reduced risk of demineralization..
                </p>






            <h2 class='text-purple-900 pt-10 sm:text-center lg:text-left h2'>
              Responsibilities with Invisalign
            </h2>


            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              It’s important to wear your aligners for at least 22 hours each day as directed by Dr. Ketcherside for them to do their job and straighten your teeth. But, with Invisalign, you can remove your aligners for special occasions.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">



              Like with traditional braces, Dr. Ketcherside will provide a retainer after Invisalign treatment to maintain your new alignment—and your confident new smile.              </p>

            </div>


        </div>
      </div>

    </div>
  </div>

</section>

<section class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Ketcherside Orthodontics Inc</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="purple underline decoration">
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9097931595" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call Now

        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>


}
