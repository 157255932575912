import { Component, OnInit, Renderer2, Inject} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Router, RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  title = 'Packing House Dental & Orthodontics, Redlands';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) { }


  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Welcome to Packing House Dental and Orthodontics. We are a Redlands orthodontist and dentist creating beautiful smiles. Call for your free consultation' }
    );

    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `
        {

          "@type": "Dentist",
          "name": "Packing House Dental and Orthodontics",
          "description": "Welcome to Packing House Dental and Orthodontics. We are a Redlands orthodontist and dentist creating beautiful smiles. Call for your free consultation",
          "slogan": "Smile Brighter",
          "openingHours": "Mo-Fr 9:00-17:00",
          "telephone": "+19097931595",
          "email": "drgaryk1@gmail.com",
          "address": {
              "@type": "PostalAddress",
              "addressLocality": "Redlands",
              "addressRegion": "CA",
              "postalCode": "92374",
              "streetAddress": "400 W Stuart Ave"
          },
          "paymentAccepted": "Cash, Credit Card",
          "priceRange": "$$",
          "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": 5,
              "reviewCount": 48
          },
          "image": "https://packinghousedentalredlands.com/assets/images1/logo1.svg",
          "review": [
              {
                  "@type": "Review",
                  "author": "Devyn",
                  "datePublished": "2020-06-26",
                  "description": "I had my braces done by Dr. Ketcherside and he did an amazing job. You will not be disappointed.",
                  "name": "Great Orthodontic Office",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": 5
                  }
              },
              {
                  "@type": "Review",
                  "author": "Katie",
                  "datePublished": "2022-10-11",
                  "description": "Myself and my 3 children has had amazing work done on our teeth by the Dr. The Dr & Staff are amazing!  Clean office and hands down give out the best cookies",
                  "name": "I love our smiles",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": 5
                  }
              }
          ]
      }
        `;

        this._renderer2.appendChild(this._document.body, script);

  }


}
