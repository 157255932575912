import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {

  title = 'About Packing House Dental and Orthodontics';


  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Our experienced and compassionate team of redlands dentists hygienists and orthodontists is committed to delivering personalized treatments in a comfortable and fun environment. Call for your free consult' }
    );
  }


}
