import { Component, ElementRef, ViewChild, AfterViewInit, OnInit, NgZone } from '@angular/core';

@Component({
  selector: 'app-ortholp4',
  standalone: true,
  imports: [],
  templateUrl: './ortholp4.component.html',
  styleUrl: './ortholp4.component.scss'
})
export class Ortholp4Component implements OnInit, AfterViewInit {
  
  date!: Date;
  now!: number;
  targetDate!: Date;
  targetTime!: number;
  difference!: number;
  months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  currentTime!: string;

  @ViewChild('days', { static: true }) days!: ElementRef<HTMLSpanElement>;
  @ViewChild('hours', { static: true }) hours!: ElementRef<HTMLSpanElement>;
  @ViewChild('minutes', { static: true }) minutes!: ElementRef<HTMLSpanElement>;
  @ViewChild('seconds', { static: true }) seconds!: ElementRef<HTMLSpanElement>;

  constructor(private zone: NgZone) {}

  ngOnInit() {
    this.targetDate = new Date(2024, 4, 30); // Month index is 0-based: January = 0
    this.targetTime = this.targetDate.getTime();
    this.currentTime = `${this.months[this.targetDate.getMonth()]} ${this.targetDate.getDate()}, ${this.targetDate.getFullYear()}`;
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.zone.run(() => {
          this.tickTock();
        });
      }, 1000);
    });
  }

  tickTock() {
    this.date = new Date();
    this.now = this.date.getTime();
    this.difference = (this.targetTime - this.now) / (1000 * 60 * 60 * 24);

    this.days.nativeElement.innerText = Math.floor(this.difference).toString();
    this.hours.nativeElement.innerText = (23 - this.date.getHours()).toString();
    this.minutes.nativeElement.innerText = (59 - this.date.getMinutes()).toString();
    this.seconds.nativeElement.innerText = (59 - this.date.getSeconds()).toString();
  }
}