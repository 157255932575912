

<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Packing House Dental & Ortodoncia 
          </span>
        </div>
        
        <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          Su Consulta de Frenos es GRATIS!
        </h1>
        <p class='text-xl leading-loose text-purple-800 max-w-2xl mt-3 text-center lg:text-left'>
          Bienvenidos a Packing House Dental y Orthodontics. Nos sentimos felizes de poder alludarle a mejorar su sonrisa. Tenemos la solución para sus necesidades de Ortodoncia y Dentales! Ofrecemos diferentes tratamientos de Ortodoncia incluso Frenos, Frenos Claros, y Invisalign.
  
        </p>
        <ul>
          <li class="flex items-center my-2">
            <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span class="text-lg">Nuestras consultas son GRATIS!</span>
          </li>
          <li class="flex items-center my-2">
            <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span class="text-lg">Repasaremos sus opciones de tratamiento ( Frenos o Invisalign).</span>
          </li>
          <li class="flex items-center my-2">
            <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span class="text-lg"> Aceptamos la mayoría de seguros. Financiamiento interno disponible para Ortodoncia. </span>
          </li>
          <li class="flex items-center my-2">
            <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
            <span class="text-lg">Consigue una hermosa sonrisa</span>
          </li>
        </ul>
        <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>
          <p class="text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mx-auto mt-14">
            Clasificado 5 estrellas por mas de <span class='font-semibold text-purple-600'>100 pacientes</span>
          </p>
        </div>
      </div>
      <div class='flex flex-col justify-center max-w-3xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6 '>
        <div class='relative'>
          <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/Braces_LPSP1.jpeg" alt="Orthodontic Treatment">
        </div>
      </div>
    </div>
    <div class=' lg:mt-12 text-center mx-auto max-w-xl lg:max-w-screen-xl'>
      <a href="tel:9093411678" class='inline-flex items-center justify-center px-8 py-3 mt-6 text-lg font-medium text-black bg-orange-400 rounded-full hover:bg-yellow-500 hover:text-black duration-300 ease-in-out sm:mt-0 sm:ml-6'>
        Llame para su consulta GRATIS 
      </a>
    </div>
  
</section>
  
  
  <section class="bg-gradient-to-b from-purple-100 to-purple-100 h-auto pb-20 pt-16">
    <div class='max-w-screen-xl mx-auto'>
      <div class="relative">
        <h2 class="max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold">
          Ponerse Frenos a una edad temprana ofrece numerosas ventajas
        </h2>
      </div>
  
    
  
      <div class='max-w-xl mx-auto  mt-10 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>
        <div class='flex flex-col justify-center  sm:mt-44 lg:mt-0 lg:col-span-6'>
          <div class="mx-auto pb-7 ">
            <span class='inline-block px-4 py-2  font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Empieze Jovena
            </span>
  
           
      
          </div>
          <div class='relative lg:col-start-1 lg:col-span-6'>
            <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/Braces_LPSP2.jpeg" alt="Orthodontic Treatment">
            </div>
          <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-3xl leading-tight tracking-tight sm:leading-tighter font-bold'>
             Corrección más fácil
          </h3>
          <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
            Los individuos más jóvenes tienen estructuras más blandas y maleables. Debido a que sus mandíbulas y dientes aún se están desarrollando, puede ser más fácil y regularmente más rápido corregir desalineaciones y problemas de mordida con aparatos ortopédicos en esta etapa. Esta maleabilidad puede conducir a un tratamiento más eficiente y en algunos casos el tiempo de tratamiento más corto.
  
          </p>
          <div class='mt-6'>
          </div>
        </div>
        <div class='relative lg:col-span-6 lg:col-start-1 lg:row-start-1'>
          <div class='w-full rounded-3xl px-10 lg:px-0'>
            
          </div>
        </div>
      </div>
      <div class='max-w-xl mx-auto  mt-10 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>
        <div class='flex flex-col justify-center  sm:mt-44 lg:mt-0 lg:col-span-6'>
          <div class="mx-auto pb-7 ">
            <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
              Salud Bucal
            </span>
          </div>
  
          <div class='relative'>
            <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/braces2.webp" alt="Orthodontic Treatment">
          </div>
    
  
          
          <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-3xl leading-tight tracking-tight  sm:leading-tighter font-bold'>
            Prevenir problemas futuros
          </h3>
          <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
            El tratamiento de ortodoncia temprano puede ayudar a prevenir que se desarrollen problemas más graves en el futuro. Al abordar tempranamente los Frenos puede evitar las desalineaciones y los problemas de mordida, es posible reducir el riesgo de caries, enfermedades de las encías e incluso la pérdida de dientes además el tratamiento temprano puede evitar la necesidad de procedimientos dentales más invasivos en el futuro.
          </p>
          <div class='mt-6'>
          </div>
        </div>
        <div class='relative lg:order-2 lg:col-span-6'>
          <div class='relative '>
           
  
          </div>
        </div>
  
      </div>
  
      <div class='max-w-xl mx-auto  mt-10 lg:grid lg:grid-cols-12 lg:gap-x-14 xl:gap-x-20 2xl:gap-x-24 lg:max-w-none'>
        <div class='flex flex-col justify-center  sm:mt-44 lg:mt-0 lg:col-span-6'>
          <div class="mx-auto pb-7 ">
            <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
              Disfruta Tu Nueva Sonrisa
            </span>
            
            <div class='relative'>
              <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/Braces_LPSP4.jpeg" alt="Orthodontic Treatment">
            </div>
      
  
          </div>
          <h3 class='max-w-4xl mx-auto text-center text-purple-900 text-3xl leading-tight tracking-tight sm:leading-tighter font-bold'>
            Aumenta el autoestima:
          </h3>
          <p class='max-w-2xl mx-auto text-center mt-3 text-lg leading-loose text-purple-800'>
            La apariencia de la sonrisa puede afectar significativamente la autoestima y la confianza, especialmente durante los años de formación. Lograr una sonrisa recta y saludable puede proporcionar un profundo impulso a la confianza de los niños y adolescentes, impactando positivamente sus interacciones sociales su rendimiento escolar y su felicidad en general.
          </p>
          <div class='mt-6'>
          </div>
        </div>
        <div class='relative lg:order-2 lg:col-span-6 lg:col-start-1 lg:row-start-1'>
          <div class='w-full rounded-3xl px-10 lg:px-0'>
           
  
          </div>
        </div>
  
      </div>
  
    </div>
  
    <div class='mt-20 lg:mt-24 lg:flex flex-col items-center'>
      <p class="text-xl tracking-wider text-purple-900 uppercase">
        Celebrando un legado de sonrisas: calificado con 5 estrellas por más de <span class='font-semibold text-purple-600'>100 PACIENTES</span>
      </p>
      <!-- Star Ratings and Call to Action Section -->
      <!-- Insert additional content or call-to-action here -->
    </div>
  </section>
  
  
  
  
  
  <section class="bg-purple-100 h-auto pb-10 pt-20">
  
    <div class='flex flex-col justify-center align-middle max-w-3xl mx-auto lg:w-1/2 w-3/4 lg:mt-0 lg:max-w-none lg:col-span-6'>
      <div
          class='relative z-10 w-full px-4 py-10 mx-auto bg-white shadow-xl rounded-3xl lg:mr-0 lg:ml-auto sm:p-16 lg:p-12 xl:p-14'>
          <div>
            <h3 class='text-2xl font-bold text-purple-900'>
              Mandenos un mensaje 
            </h3>
            <p class='text-purple-800 mt-0.5 text-opacity-90'>
              Nos comunicaremos con usted lo antes posible.
            </p>
          </div>
  
          <div class="pt-7">
            </div>
  
            <form class="contact-form" (submit)="sendEmail($event)">
              <label class="block">
                <span class="text-gray-700">Su nombre:</span>
                <input type="text" name="name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Sophia Doe">
              </label>
              <!-- Email Input -->
         
              <!-- Phone Number Input -->
              <label class="block">
                <span class="text-gray-700">Número de teléfono:</span>
                <input type="tel" name="phone" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="123-456-7890">
              </label>
  
              <label class="block">
                <span class="text-gray-700">Prefiere mensaje de texto o llamada:</span>
                <select name="contact-preference" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                  <option value="text">Texto</option>
                  <option value="call">Llamada</option>
                </select>
              </label>
    
              <!-- Message Input -->
              <label class="block">
                <span class="text-gray-700">¿Cómo Podemos Ayudar?:</span>
                <textarea name="message" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3"></textarea>
              </label>
  
              <label class="hidden">
                <input name="location" value="Redlands Office" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
              </label>
  
              <label class="hidden">
                <input name="page" value="orthobracesad" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" rows="3">
              </label>
    
              <!-- Submit Button -->
              <button type="submit" value="Send"  class="inline-flex justify-center py-2 mt-6 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Enviar
              </button>
            </form>
  
          </div>
    </div>
  
  
  </section>
  
  
  
  
  
  <section loading="lazy" class=' bg-gradient-to-b from-purple-100 to-yellow-50  '>
  
    <div class='px-4 mx-auto lg:max-w-screen-2xl sm:px-6'>
  
      <div class='flex flex-col items-center justify-center'>
        <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
          Opiniones
        </h1>
  
      </div>
  
      <div class='grid gap-8 mt-12 md:gap-8 sm:gap-6 md:mt-14 lg:mt-16 xl:mt-20 2xl:mt-24 xl:grid-cols-4 sm:grid-cols-2 2xl:gap-12 lg:gap-6'>
  
        <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-yellow-200 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
          <img loading="lazy" src='../../../../assets/images1/d.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt='Testimonial 01'>
          <p class='mt-3 text-lg font-bold text-purple-900'>
            Devyn
          </p>
  
          <div class='mt-1 flex justify-start w-full space-x-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
  
          <p class='mt-5 text-lg text-purple-800'>
            “I had my braces done by Dr. Ketcherside and he did an amazing job. Him and his staff are always so welcoming, kind and very helpful. I highly recommend going to Dr. Ketcherside. You will not be disappointed."        </p>
        </blockquote>
  
        <blockquote class='px-8 py-8  bg-purple-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl hidden lg:block'>
          <img loading="lazy" src='../../../../assets/images1/a.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full  ' alt="Testimonial 02">
          <p class='mt-3 text-lg font-bold text-purple-900'>
            Autumn
          </p>
  
          <div class='mt-1 flex justify-start w-full space-x-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
  
          <p class='mt-5 text-lg text-purple-800'>
            "Friendly staff, the office is really nice and clean. This was my first visit and they explained all the procedures clearly and made me feel comfortable."        </p>
        </blockquote>
  
        <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-rose-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl hidden lg:block'>
          <img src='../../../../assets/images1/g.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt="Testimonial 03">
          <p class='mt-3 text-lg font-bold text-purple-900'>
            G J
          </p>
  
          <div class='mt-1 flex justify-start w-full space-x-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
  
          <p class='mt-5 text-lg text-purple-800'>
            “This office is the best! The front desk staff are amazing and friendly!  Dr Ketcherside is very kind and professional. I highly recommend this office to anyone looking for dental care and to be treated like royalty while you are there.”        </p>
        </blockquote>
  
        <blockquote class='px-8 py-8 transition duration-300 ease-in-out bg-blue-50 sm:px-6 md:px-8 lg:px-5 2xl:px-8 rounded-3xl'>
          <img  loading="lazy" src='../../../../assets/images1/k.webp' class='object-cover w-20 h-20 border-2 border-yellow-300 rounded-full ' alt="Testimonial 04">
          <p class='mt-3 text-lg font-bold text-purple-900'>
            Katie
          </p>
  
          <div class='mt-1 flex justify-start w-full space-x-0.5'>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
  
          <p class='mt-5 text-lg text-purple-800'>
            "We have been coming to Dr. Ketcherside in Corona for over 7yrs. Myself and my 3 children has had amazing work done on our teeth by the Dr. The Dr & Staff are amazing!!!  Super welcoming and friendly. Clean office and hands down give out the best cookies."        </p>
        </blockquote>
  
      </div>
  
    </div>
  
  </section>
  
  
  <section class='relative w-full px-4 py-14 bg-purple-100   sm:px-6 lg:px-8'>
  
    <h1 class='max-w-4xl mx-auto text-center text-purple-900 text-4xl leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
      Situado En Redlands
    </h1>
    
  <div class='mt-16 lg:mx-auto lg:mt-24 rounded-3xl lg:max-w-screen-xl'>
    <iframe class='w-full rounded-3xl'
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13221.509369976533!2d-117.187178!3d34.05984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcaa5b282b539d%3A0x5e63f45149dae175!2sPacking%20House%20Dental%20%26%20Orthodontics!5e0!3m2!1sen!2sus!4v1675959670156!5m2!1sen!2sus"
      height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
  </section>
  
  
  