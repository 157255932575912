import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-dentist',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './dentist.component.html',
  styleUrl: './dentist.component.scss'
})
export class DentistComponent {
  title = 'Dentist in Redlands CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Top rated Redlands Dentist Call today at (909) 793-1595 to learn more about our dental services or to schedule an appointment.' }
    );
    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `{
          "@context": "http://schema.org",
          "@type": "Dentist",
          "name": "Packing House Dental & Orthodontics",
          "description": "Our experienced dentist in Redlands, CA, offers comprehensive dental care to meet your oral health needs. Call now to make an appointment!",
          "slogan": "Smile Brighter",
          "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
              ],
              "opens": "09:00",
              "closes": "17:00"
          },
          "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+19097931595",
              "contactType": "Customer Service",
              "email": "drgaryk1@gmail.com"
          },
          "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Dental Services Offered",
            "itemListElement": [
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Bonding" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Bone Grafting" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Bridges" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Crowns" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Dental Exams" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Root Canals" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Dental Implant Surgery" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Dental Implants" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Extractions" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Fillings" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Fluoride Treatment" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Implant Restoration" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Mouthguards" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Nightguards" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Scaling and Root Planning" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Sealants" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Teeth Cleaning" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Teeth Whitening" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Tooth Colored Fillings" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "Veneers" }},
              { "@type": "Offer", "itemOffered": { "@type": "Service", "name": "X-Rays" }}
            ]
          },
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "400 W Stuart Ave Suite 110",
              "addressLocality": "Redlands",
              "addressRegion": "CA",
              "postalCode": "92374"
          },
          "paymentAccepted": "Cash, Credit Card",
          "priceRange": "$$",
          "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "48"
          },
          "image": "https://packinghousedentalredlands.com/assets/images1/logo1.svg",
          "reviews": [
              {
                  "@type": "Review",
                  "author": "Devyn",
                  "datePublished": "2020-06-26",
                  "reviewBody": "I had my braces done by Dr. Ketcherside and he did an amazing job. You will not be disappointed.",
                  "name": "Great Office",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  }
              },
              {
                  "@type": "Review",
                  "author": "Katie",
                  "datePublished": "2022-10-11",
                  "reviewBody": "Myself and my 3 children have had amazing dental work by Dr. Ketcherside. The staff is wonderful, the office is clean, and they even have the best cookies!",
                  "name": "I love our smiles",
                  "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5"
                  }
              }
          ]
      }
      `;

        this._renderer2.appendChild(this._document.body, script);

  }





}
