import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-teeth-cleaning',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './teeth-cleaning.component.html',
  styleUrl: './teeth-cleaning.component.scss'
})
export class TeethCleaningComponent {

  title = 'Teeth Cleanings in Redlands, CA';


  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document

  ) { }



  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Professional teeth cleanings at Packing House Dental & Orthodontics ensure optimal oral health, removing plaque, and help prevent gum disease.' }
    );

    let script = this._renderer2.createElement('script');
        script.type = `application/ld+json`;
        script.text = `{
          "@context": "http://schema.org",
          "@type": "Service",
          "serviceType": "Dental Cleaning",
          "name": "Teeth Cleanings",
          "description": "Professional teeth cleanings at Packing House Dental & Orthodontics ensure optimal oral health, removing plaque, and preventing gum disease. Our gentle and thorough cleanings are suitable for all ages, helping maintain a healthy and bright smile.",
          "provider": {
            "@type": "LocalBusiness",
            "name": "Packing House Dental & Orthodontics",
            "areaServed": {
              "@type": "City",
              "name": "Redlands"
            },
            "category": "Dental Services",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "400 W Stuart Ave Suite 110",
              "addressLocality": "Redlands",
              "addressRegion": "CA",
              "postalCode": "92374"
            },
            "telephone": "+19097931595",
            "email": "drgaryk1@gmail.com",
            "image": "https://packinghousedentalredlands.com/assets/images1/logo1.svg"
          },
          "areaServed": {
            "@type": "Place",
            "name": "Redlands, CA"
          },
          "openingHours": "Mo-Fr 9:00-17:00",
          "paymentAccepted": "Cash, Credit Card",
          "priceRange": "$"
        }


        `;

        this._renderer2.appendChild(this._document.body, script);

  }
}
