import { Component } from '@angular/core';

@Component({
  selector: 'app-ortholp3',
  standalone: true,
  imports: [],
  templateUrl: './ortholp3.component.html',
  styleUrl: './ortholp3.component.scss'
})
export class Ortholp3Component {

}
