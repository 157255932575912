@defer (prefetch on idle) {

<section class='bg-gradient-to-b from-purple-50 to-purple-100 px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Free Consultation
        </span>
      </div>
      <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Clear Braces in Redlands
      </h1>
      <p class='mt-3 text-xl leading-loose text-purple-800 max-w-2xl text-center lg:text-left'>
        Explore the aesthetic and effective solution of Clear Braces in Redlands, combining traditional efficiency with modern discretion.
      </p>
      <ul>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Aesthetic Appeal: Less noticeable than metal braces, perfect for image-conscious individuals.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Effective Treatment: Offers the same reliable results as traditional braces for a wide range of dental issues.</span>
        </li>
        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Cost-Effective: Offering the perfect blend of aesthetics and affordability.</span>
        </li>

        <li class="flex items-center my-2">
          <img class='mr-3 w-7 h-7 flex-shrink-0' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Suitable for All Ages: Ideal for both teens and adults seeking a less visible orthodontic solution.</span>
        </li>
      </ul>
      <div class='mx-auto overflow-hidden flex flex-col items-center sm:flex-row'>
        <p class="text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mx-auto mt-14">
          Over <span class='font-semibold text-purple-600'>25 years</span> of Orthodontic experience.
        </p>
      </div>
    </div>
    <div class='flex flex-col justify-center mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6 max-w-3xl'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/ceramic.webp" alt="Clear Braces Smile">
      </div>
    </div>
  </div>
  <div class='mt-10 lg:mt-12 text-center mx-auto max-w-xl lg:max-w-screen-xl'>
    <a href="tel:9093411678" class='inline-flex items-center justify-center px-8 py-3 mt-6 text-lg font-medium text-black bg-orange-500 rounded-full hover:bg-yellow-500 hover:text-black duration-300 ease-in-out sm:mt-0 sm:ml-6'>
      Schedule a Free Consultation
    </a>
  </div>
</section>






<section class='px-4 -mt-8  bg-purple-100 sm:py-4 sm:mt-0 sm:px-6 lg:px-8'>

  <div class='max-w-3xl mx-auto lg:max-w-screen-xl pb-20'>
    <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1 pb-10">
      About Clear Braces
    </h2>

    <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

      <div class='flex flex-col justify-center'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
          The Differences Between Ceramic & Metal Braces
        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Metal braces are known for their durability and strength. While ceramic braces are also tough, they are made from a different, more fragile material that is more prone to chip or crack. Traditional braces are also better at tolerating pressure than newer ceramic ones.        </p>
          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

            If you need more drastic corrections, metal braces could be a better option. Adjustments with ceramic braces have to be slightly smaller, so it can take a little more time to align your teeth.

          </p>

          <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
Having said that, both types of braces work on the same principles and tend to be equally as effective in most cases.

</p>

<p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
  You may be worried about ceramic braces staining, but that hasn’t been a problem for several years. Ceramic brackets won’t stain, but the adhesive used to attach the brackets to the teeth and the clear elastic ties used to hold the wires in place can stain.
</p>
  <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
    This can give an overall impression of discoloration or staining over time. You can choose to avoid things like coffee and tea, red wine, tomatoes, mustard, etc. to cut back on the chance of any staining. This isn’t necessary, since Dr. Ketcherside Orthodontics will change your elastics at every adjustment appointment.

  </p>

  <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
    If you maintain a good oral hygiene routine and follow the treatment plan designed by Dr. Ketcherside, you shouldn’t have to worry too much about giving up your morning cup of coffee or evening glass of wine!
</p>









      </div>

      <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
        <div class='aspect-w-3 aspect-h-4'>
          <div class='flex flex-col justify-center'>
            <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
              Cost of Ceramic Braces
            </h2>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              In our Redlands office, ceramic brackets are a little more expensive than traditional braces but cheaper than invisalign.
                        </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              One of the biggest factors that affect your treatment cost will be the length and complexity of your treatment.

            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              Treatment fees will vary depending on the complexity of your orthodontic treatment. Dr. Ketcherside will determine a treatment fee that fits your specific orthodontic needs, and our dedicated team will do everything we can to accommodate you and your budget.

            </p>

            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              The work it takes to put your braces on, adjust them, and take them off must also be factored into the total treatment cost, because placing and removing ceramic braces demand more technique and require more time from Dr. Ketcherside and our team.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              The good news is, we offer flexible payment plans, accept care credit and all PPO dental insurances. </p>

            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              Our treatment coordinators will be happy to go over the cost with you at your free consultation.
                        </p>


            </div>


        </div>
      </div>

    </div>
  </div>

</section>

<section class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Ketcherside Orthodontics Inc</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="purple underline deoration">
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9097931595" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call Now

        <!-- Tablericon name: arrow-narrow-right -->
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>


}