@defer (prefetch on idle) {
<section class='relative px-4 py-16 overflow-hidden  sm:px-6 lg:px-8 bg-purple-50'>

  <img src='../../../../assets/images1/dots-large-grid.svg' class='hidden lg:block absolute right-0 h-auto transform opacity-25 lg:opacity-40 top-12 w-54' alt="dot decoration">
  <img src='../../../../assets/images1/dots-large-grid.svg' class='hidden lg:block absolute h-auto transform opacity-40 -left-36 bottom-36 w-54' alt="dot decoration">

  <div class='max-w-xl mx-auto pb-28 lg:max-w-screen-xl lg:grid lg:grid-cols-12 lg:gap-8'>

    <div class='flex flex-col justify-center lg:col-span-5'>
      <div>
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Financial & Insurance
        </span>
      </div>

      <div class='relative'>
        <h1 class='max-w-xl mt-4 text-purple-900 sm:mt-5 h1 lg:max-w-none'>
          We make it easy to pay for dental services
        </h1>

        <img src='../../../../assets/images1/arrow-right-over.svg' class='absolute hidden transform -top-3 xl:block md:-right-8 w-14 md:w-32 rotate-12' alt='arrow decoration'>

      </div>

      <p class='max-w-2xl mt-3 text-xl leading-loose text-purple-800 sm:mt-4'>
        We accept more insurance plans than the average orthodontic office in Redlands because we believe having a great smile should be affordable. When you schedule your free appointment ask us to check your California dental insurance benefits before you arrive and we can give you a complete financial breakdown right away.      </p>

      <!-- Enroll CTA-->
      <div class='mt-10 font-semibold lg:mt-12'>
        <a href="tel:9097931595" class='mt-6 text-lg font-medium text-black bg-yellow-300 sm:mt-0 sm:ml-6 relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
          Call Now



        </a>
      </div>

    </div>




    <div class='hidden lg:block relative mt-12  sm:mt-16 lg:mt-0 xl:pl-12 lg:col-span-7'>

      <div class='relative w-full mx-auto sm:grid sm:gap-6 md:max-w-3xl lg:gap-5 lg:max-w-lg sm:grid-cols-4 lg:grid-cols-2 auto-rows-fr'>

        <a class='flex flex-col justify-center w-full p-8 duration-300 ease-in-out transform shadow-lg sm:col-span-2 lg:col-span-1 bg-purple-50 rounded-2xl hover:scale-105 group'>

          <div class='flex-1'>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
              <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
            </svg>

            <p class='mt-4 text-xl font-semibold text-purple-900'>
              CareCredit Payment Plans
            </p>
            <p class='mt-2 text-sm text-purple-800'>
              To help lower the cost of dental care
            </p>
          </div>

          <div class='flex justify-end w-full mt-6'>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="7 7 12 12 7 17" />
              <polyline points="13 7 18 12 13 17" />
            </svg>

          </div>
        </a>

        <a  class='flex flex-col justify-center w-full p-8 mt-6 duration-300 ease-in-out bg-yellow-200 shadow-lg rounded-2xl sm:mt-0 sm:col-span-2 lg:col-span-1 lg:translate-y-28 hover:scale-105 group'>

          <div class='flex-1'>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-purple-700" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />
              <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />
              <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />
              <line x1="11" y1="6" x2="20" y2="6" />
              <line x1="11" y1="12" x2="20" y2="12" />
              <line x1="11" y1="18" x2="20" y2="18" />
            </svg>

            <p class='mt-4 text-xl font-semibold text-purple-900'>
              Insurance
            </p>
            <p class='mt-2 text-sm text-purple-800'>
              We accept most major insurances
            </p>
          </div>

          <div class='flex justify-end w-full mt-6'>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="7 7 12 12 7 17" />
              <polyline points="13 7 18 12 13 17" />
            </svg>

          </div>
        </a>

        <a  class='flex flex-col justify-center w-full p-8 mt-6 duration-300 ease-in-out shadow-lg bg-rose-50 sm:col-span-2 sm:mt-0 lg:col-span-1 rounded-2xl hover:scale-105 group'>

          <div class='flex-1'>

            <img class="left-5" width="32" height="32" viewBox="0 0 46 46" fill="none" src="../../../../assets/images1/finan.svg" alt="financing emblem">


            <p class='mt-4 text-xl font-semibold text-purple-900'>
              In house Financing for Orthodontics
            </p>
            <p class='mt-2 text-sm text-purple-800'>

            </p>
          </div>

          <div class='flex justify-end w-full mt-6'>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="7 7 12 12 7 17" />
              <polyline points="13 7 18 12 13 17" />
            </svg>

          </div>
        </a>

        <a class='flex flex-col justify-center w-full p-8 mt-6 duration-300 ease-in-out shadow-lg sm:mt-0 lg:col-start-auto sm:col-span-2 lg:col-span-1 lg:translate-y-28 bg-teal-50 rounded-2xl hover:scale-105 group'>

          <div class='flex-1'>

              <img class="left-5" width="46" height="46" viewBox="0 0 46 46" fill="none" src="../../../../assets/images1/discount.svg" alt="discounts">



            <p class='mt-4 text-xl font-semibold text-purple-900'>
              Full Payment Discount
            </p>
            <p class='mt-2 text-sm text-purple-800'>
              5% discount for payment in full
            </p>
          </div>

          <div class='flex justify-end w-full mt-6'>

            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-purple-500 duration-300 ease-in-out group-hover:animate-horizontal-bounce hover:text-purple-600" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="7 7 12 12 7 17" />
              <polyline points="13 7 18 12 13 17" />
            </svg>

          </div>
        </a>



      </div>

    </div>


  </div>

</section>




<section class='px-4 pt-10  bg-purple-50 '>

  <div class='max-w-3xl mx-auto lg:max-w-screen-xl pb-20'>
    <h2 class="max-w-3xl mx-auto mt-4 text-center text-purple-900 h1 pb-10">
      Affordable Options
    </h2>

    <div class='lg:grid lg:grid-cols-2 lg:gap-10 xl:gap-16'>

      <div class='flex flex-col justify-center'>
        <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
          Finances should never stand in the way of your stunning smile.

        </h2>
        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Learn all about our convenient financing and payment options right here.

        </p>

        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Our team will develop a treatment plan specifically for you and then determine each available payment plan option that best suits your needs.
        </p>

        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          That way, you can make the best choice for yourself and your smile.


        </p>

        <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
          Does your employer offer a flexible spending plan? If so, we can contribute these pre-tax dollars towards your orthodontic expenses!
        </p>

        <div class='grid max-w-4xl gap-6 mt-10 sm:grid-cols-2 lg:grid-cols-1 sm:mx-auto lg:mx-0 lg:max-w-md'>
          <h2 class='text-purple-900 pt-2 sm:text-center lg:text-left h2'>
            We offer:
          </h2>

          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                Interest-free financial plans for Orthodontics

              </h5>

            </div>
          </div>

          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                No-down payment, third party financing from CareCredit
              </h5>

            </div>
          </div>






          <div class="flex">
            <div class="w-14">
              <img src='../../../../assets/images1/checkmark.svg' class='w-7 h-7' alt='checkmark decoration'>
            </div>
            <div class="w-full">
              <h5 class="flex items-center text-xl font-semibold text-purple-900">
                Convenient credit card payments
                            </h5>

            </div>
          </div>
        </div>









        <!-- Values -->




      </div>

      <!-- Section image -->
      <div class='w-full max-w-xl mx-auto mt-16 lg:mt-0 lg:max-w-none lg:mx-0'>
        <div class='aspect-w-3 aspect-h-4'>
          <div class='flex flex-col justify-center'>
            <h2 class='text-purple-900 sm:text-center lg:text-left h2'>
              Insurance
            </h2>

            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              Many dental insurance policies will cover a portion of your orthodontic treatment at Packing House Dental & Orthodontics
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              During your initial consultation at our Redlands office, our team will review your orthodontic coverage and explain your benefits to you.
                        </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">

              Once treatment begins, we will gladly submit and file your insurance claim on your behalf to make sure your benefits are maximized. To expedite your insurance filing, please be sure to provide your insurance information before your first free consultation.
            </p>
            <p class="max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0">
              Please remember that orthodontic insurance is a benefit for you or your child. If your benefits change anytime during treatment, the financially responsible party is accountable for all charges.
                        </p>


            <!-- Values -->



            </div>

            <div class='flex flex-col justify-center pr-10 xl:pr-0'>

              <h2 class='mt-3.5 sm:mt-4 h2 max-w-xl text-[#4a499c]'>
                Our Financing Plans
              </h2>
              <p class='max-w-xl mx-auto mt-4 text-lg leading-relaxed text-purple-800 sm:text-center lg:text-left md:mt-5 lg:mx-0'>
                We offer flexible payment plans through care credit. Talk to our office about the financing plan that best fits your budget.        </p>

              <!-- Contact link -->
              <div class='mt-8 font-medium lg:mt-10'>
                <p class='text-purple-800'>Want to learn more about our programs?</p>
                <div class='flex  mt-8'>
                  <a href="tel:9097931595" class='mt-6 text-lg font-medium text-black bg-yellow-300 sm:mt-0 sm:ml-6 relative leading-normal inline-flex items-center justify-center px-8 py-3 duration-300 ease-in-out rounded-full outline-none hover:text-black hover:bg-yellow-500'>
                    Call Now

                    <!-- Tablericon name: arrow-narrow-right -->


                  </a>
                </div>
              </div>
            </div>


        </div>
      </div>

    </div>
  </div>

</section>










<section class='relative w-full px-4 py-16 sm:py-24 lg:py-32 sm:px-6 lg:px-8'>

  <!-- Container -->
  <div class='max-w-xl mx-auto lg:max-w-screen-xl'>

    <!-- Section header text -->
    <div class='lg:grid lg:grid-cols-2 lg:gap-16 xl:gap-32'>
      <div class='flex items-center'>
        <h2 class='max-w-4xl text-purple-900 h2'>
          Insurances: We accept all PPO dental insurances.
        </h2>
      </div>
      <div class='flex items-center mt-6 lg:mt-0'>
        <p class='text-xl leading-relaxed text-purple-800'>
          Below is a list of major dental insurance plans we accept in our office. Don’t see your plan listed? Give us a call at <br>(909) 793-1595 to confirm.
        </p>
      </div>
    </div>

    <!-- Class info -->
    <div class='grid gap-8 mt-12 sm:max-w-none sm:grid-cols-2 sm:mt-14 lg:mt-24 lg:grid-cols-4 sm:gap-6 xl:gap-12'>

      <!-- Info 1 -->
      <div class='flex flex-col items-center justify-center px-4 py-6 bg-yellow-200 sm:p-8 sm:py-10 rounded-2xl'>
        <!-- <h2>A-C</h2> -->
        <h4>
          Adventist Risk Management<br>
          Aetna PPO<br>
          Aetna Dental PPO<br>
          Anthem/BC Dental Blue PPO<br>
          Ameritas PPO<br>
          Anthem Blue Cross PPO<br>
          Anthem PPO<br>
          AVS Discount Plana<br>
          Carrington<br>



        </h4>


      </div>

      <!-- Info 2 -->
      <div class='flex flex-col items-center justify-center px-4 py-6 sm:p-8 sm:py-10 rounded-2xl bg-purple-50'>
        <h4>
          Cigna PPO<br>
          Cypress Dental<br>
          Cypress Benefits PPO<br>
          Delta Dental PPO<br>
          Delta Dental California<br>
          Delta Dental Federal Government<br>
          Delta Dental Premier<br>
          DenteMax PPO<br>
          Dentegra PPO<br>
        </h4>

      </div>

      <!-- Info 3 -->
      <div class='flex flex-col items-center justify-center px-4 py-6 sm:p-8 sm:py-10 rounded-2xl bg-rose-50'>
        <h4>
          Delta Dental Of Kansas<br>
          First Dental Health PPO<br>
          GEHA/Connection PPO<br>
          Guardian PPO<br>
          Humana/CompBenefits PPO<br>
          Humana<br>
          Lineco<br>
          Maverest/Zelis PPO<br>
          MetLife PPO<br>
          Premier Access PPO<br>
        </h4>

      </div>

      <!-- Info 4 -->
      <div class='flex flex-col items-center justify-center px-4 py-6 sm:p-8 sm:py-10 bg-blue-50 rounded-2xl'>
        <h4>
          Mutual of Omaha Insurance<br>
          Operating Engineers<br>
          Principal Financial<br>
          Reliant Asset Management<br>
          Risk Management<br>
          Sun Life Dental Network<br>
          Teamsters Food And Employer<br>
          Teamcare<br>
          UFCW<br>
          United Concordia<br>
          United Concordia ADDP<br>
          Western Dental<br>

        </h4>

      </div>

    </div>

  </div>

</section>



}