<section class='bg-gradient-to-b from-purple-50 to-white px-4 pt-16 sm:px-6 lg:px-8 z-auto'>
  <div class='max-w-screen-xl mx-auto lg:grid lg:grid-cols-12 lg:gap-8 z-auto'>
    <div class='flex flex-col items-center justify-center lg:items-start lg:col-span-6'>
      <div class="mx-auto pb-7 ">
        <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
          Brighten Your Smile

        </span>
      </div>
      <h1 class='text-4xl text-purple-900 max-w-4xl mx-auto text-center leading-tight tracking-tight sm:text-5xl xl:text-6xl sm:leading-tighter font-bold'>
        Zoom Teeth Whitening in Redlands
      </h1>
      <p class='text-xl leading-loose text-purple-800 max-w-2xl mt-3 text-center lg:text-left'>
        Experience the magic of Zoom Teeth Whitening for a brighter, more confident smile in just one visit.
      </p>
      <ul>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Fast and Efficient: Noticeable results in just one session.</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Safe and Comfortable: Performed by professional dental experts.</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Long-Lasting Results: Enjoy a brighter smile for longer.</span>
        </li>
        <li class="flex items-center  my-2">
          <img class='flex-shrink-0 mr-3 w-7 h-7' loading="lazy" src='../../../../assets/images1/checkmark.svg' alt="checkmark decoration">
          <span class="text-lg">Customized Treatments: Tailored to meet your individual needs.</span>
        </li>
      </ul>
      <div class='flex flex-col items-center mx-auto overflow-hidden sm:flex-row'>
        <p class="text-lg font-medium tracking-wider text-purple-900 uppercase sm:block lg:hidden xl:block mx-auto mt-14">
          Over <span class='font-semibold text-purple-600'>25 years</span> of experience in creating radiant smiles.
        </p>
      </div>
    </div>
    <div class='flex flex-col justify-center mx-auto mt-16 lg:mt-0 lg:max-w-none lg:col-span-6 max-w-3xl'>
      <div class='relative'>
        <img class="w-full h-auto rounded-2xl" src="../../../../assets/images1/zoom1.webp" alt="Zoom Teeth Whitening">
      </div>
    </div>
  </div>
</section>



<section class='relative w-full px-4 py-16 sm:py-24 lg:py-32 sm:px-6 lg:px-8'>

  <div class='max-w-xl mx-auto lg:max-w-screen-xl'>

    <div class='lg:grid lg:grid-cols-2 lg:gap-16 xl:gap-32'>
      <div class='flex items-center'>
        <h2 class='max-w-4xl text-purple-900 h2'>
          Come in with stained teeth and leave with pearly whites
        </h2>
      </div>
      <div class='flex items-center mt-6 lg:mt-0'>
        <p class='text-xl leading-relaxed text-purple-800'>
           Zoom teeth whitening gives you instant results, no more wearing uncomfortable trays at night with barely any results.
        </p>
      </div>
    </div>





  </div>

</section>




<section class='relative overflow-hidden'>

  <div class='absolute inset-y-0 right-0 w-full max-w-screen-xl ml-auto bg-[#4a499c] rounded-l-5xl'></div>

  <img src='../../../../assets/images1/dots-chaos.svg' class='absolute bottom-0 right-0 z-10 hidden h-auto transform 2xl:block w-80' alt='dot decoration'>
  <img src='../../../../assets/images1/dots-strip.svg' class='absolute z-10 hidden h-auto transform 2xl:block top-1 right-20 w-36' alt='dot decoration'>

  <div class='relative w-full px-4 py-16 bg-[#4a499c] 2xl:max-w-screen-xl 2xl:mx-auto 2xl:rounded-l-3xl sm:px-6 lg:pr-0 sm:py-24 lg:py-0'>
    <div class='relative grid gap-12 lg:grid-cols-2 2xl:gap-4 lg:pr-10 2xl:pr-0'>
      <div class='relative grid order-2 w-full max-w-2xl grid-cols-2 gap-3 mx-auto lg:order-1 sm:gap-6 lg:py-32 lg:max-w-none'>

        <img src='../../../../assets/images1/dots-purple-mess.svg' class='absolute hidden transform lg:block top-12 -right-16 2xl:right-0 ' alt='dot decoration'>
        <img src='../../../../assets/images1/dots-grid.svg' class='absolute top-0 hidden h-auto transform translate-y-12 right-2 w-80' alt='dot decoration'>
        <div class='col-span-2 transform aspect-w-3 aspect-h-4 2xl:-translate-x-16'>
          <img src='../../../../assets/images1/teethwhitening2.webp' class='object-cover w-full h-full rounded-3xl 2xl:rounded-4xl' alt='Teeth Whitening before and after'>
        </div>
        <div class=''>
          <div class='aspect-w-1 aspect-h-1'>
            <img src='../../../../assets/images1/gallery10.webp' class='object-cover w-full h-full rounded-3xl 2xl:rounded-4xl' alt='Teeth whitening results'>
          </div>
        </div>
   
      </div>

      <div class='flex flex-col  order-1 lg:order-2 lg:py-36 '>
        <div class='relative z-20 mx-auto prose prose-lg prose-light sm:prose-xl'>
          <h2 class="h1 pb-12 text-white">
            About zoom whitening
          </h2>
          <p class="max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-white sm:mt-5 text-left">
            Professional whitening is quick and easy with Zoom Teeth Whitening.  Zoom Blue LED light-accelerated technology and gel whitens rapidly while Amorphous Calcium Phosphate (ACP) helps deliver enamel protection, improve luster and reduce sensitivity.   </p>
          <h3 class="h2 pt-16 pb-10 text-white">Whitening Process</h3>
          <p class="max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-white sm:mt-5 text-left">
            Once Brandie has checked the current shade of your teeth, she will take you through these simple steps:          </p>
          <ul class="space-y-6 pb-10 max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-white sm:mt-5 text-left">
            <li>•    Prepares your mouth and gums.</li>
            <li>•    A thin layer of whitening varnish is applied to each tooth, drying almost instantly.</li>
            <li>•    This is followed by a layer of protective sealant that keeps your mouth safe from sensitivity..</li>
            <li>•    You can be on your way! Whether you head home, or back to the office, all that’s left is to brush off the dried varnish after 30 minutes.</li>
            <li>•    Enjoy your new unstained smile all your friends will be talking about!</li>
            </ul>
            <p class="space-y-6 pb-10 max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-white sm:mt-5 text-left">
                After treatment you get free take home tooth whitening kit to maintain your new smile!
            </p>

        </div>
      </div>
    </div>
  </div>
</section>
