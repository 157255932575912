<section class='px-4 pb-8 pt-16 sm:px-6 lg:px-8 bg-purple-50'>

  <div class='max-w-screen-xl mx-auto'>

    <div class="relative">
      <h2 class="max-w-3xl mx-auto text-center text-purple-900 h1">
        Why Packing House Dental & Orthodontics?
      </h2>
      <p class="max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800 sm:mt-5">
        Experience counts and 40+ years of orthodontic & dental experience says it all: "Excellence in Healthcare", "Attention to detail", "We'd never go anywhere else" and "We Love their smiles"
      </p>
      <p class="max-w-2xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-800 sm:mt-5">
        Your Orthodontic or Dental consultation is free! We have a network of doctors, hygienists and orthodontists who strive to keep your teeth healthy and sparkling!

      </p>
    </div>



  </div>

</section>
@defer (prefetch on idle) {


<section class='px-4 overflow-hidden bg-purple-50 pb-28 sm:pb-36 sm:px-6 lg:px-8'>

  <div class='max-w-screen-xl mx-auto'>




    <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">

      <div class="relative z-10 flex flex-col justify-center order-2 lg:order-1 lg:col-span-6 lg:text-left">

        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Individualized Treatment
          </span>
        </div>

        <div>
          <h1 class="mt-3.5 font-bold text-purple-900 h3">
            Developing Beautiful Smiles For The Whole Family
          </h1>
          <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            We recognize that all of our patients are unique and deserve to receive dental and orthodontic care that reflects their individual needs. Our experienced and talented team is committed to working with you and your family to create a comfortable, stress-free, and rewarding experience every time you visit our practice. We are pleased to welcome you and your family, and we look forward to working with you.          </p>
        </div>

      </div>

      <div class="relative order-1 w-full max-w-xl mx-auto lg:order-2 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

        <div class="hidden lg:block">
          <img src='../../../../assets/images1/blob-light-yellow.svg' class='absolute inset-0 w-full h-full transform lg:scale-150' alt='yellow blob'>
        </div>

        <img src='../../../../assets/images1/dots-large-strip.svg' alt="dots" class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt=''>

        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mr-0 lg:ml-auto">
          <div class="relative block w-full">

            <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -left-20 xl:w-48 xl:-top-20 xl:-left-20' src='../../../../assets/images1/dots.svg' alt='dots'>

            <figure class='md:order-1'>
              <img src='../../../../assets/images1/about_01.webp' class='object-cover object-center shadow-xl rounded-3xl' alt='Happy Family'>
            </figure>

          </div>
        </div>
      </div>

    </div>

    <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">

      <div class="relative z-10 flex flex-col justify-center order-2 lg:col-span-6 lg:text-left">

        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Highly Trained
          </span>
        </div>

        <div>
          <h1 class="mt-3.5 font-bold text-purple-900 h3">
            Providing The Highest Quaility Care
          </h1>
          <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            Our staff participates in seminars and training not only to enhance their skill but also to enrich the ability to provide you with the best quality of care and customer service. Paying attention to detail is a talent, and all of us at the office of Ketcherside Orthodontics Inc main goal is to step by step walk you through the highest quality of care and most comfortable, enjoyable experience.          </p>
        </div>

      </div>

      <div class="relative order-1 w-full max-w-xl mx-auto lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

        <div class="hidden lg:block">
          <img src='../../../../assets/images1/blob-light-rose.svg' class='absolute inset-0 w-full h-full transform lg:scale-150' alt='rose blob'>
        </div>

        <img src='../../../../assets/images1/dots-large-strip.svg' alt="dots" class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt=''>

        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mx-0">
          <div class="relative block w-full">

            <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -right-20 xl:w-48 xl:-top-20 xl:-right-20' src='../../../../assets/images1/dots.svg' alt='dots'>

            <figure class='aspect-w-12 aspect-h-10 md:order-1'>
              <img src='../../../../assets/images1/about5.webp' class='object-cover object-center w-full h-full shadow-xl rounded-3xl' alt='Dentist reviewing x-rays'>
            </figure>

          </div>
        </div>
      </div>

    </div>

    <div class="grid max-w-xl mx-auto mt-20 lg:max-w-none sm:mt-24 lg:mt-44 lg:grid-cols-12 gap-14 sm:gap-16 lg:gap-8">

      <div class="relative z-10 flex flex-col justify-center order-2 lg:order-1 lg:col-span-6 lg:text-left">

        <div>
          <span class='inline-block px-4 py-2 font-medium text-purple-700 bg-purple-200 rounded-full shadow-md -rotate-1'>
            Quick and easy
          </span>
        </div>

        <div>
          <h1 class="mt-3.5 font-bold text-purple-900 h3">
            Family Oriented, Fun and Friendly Environment          </h1>
          <p class="max-w-xl mt-3 text-lg text-purple-800 sm:leading-relaxed sm:text-xl">
            Do not be surprised if you are greeted by your name even on your first visit, that is because we know all of our patients and parents by first name and will easily recognize a new face. Our office excels at welcoming you the same we would a member of our family          </p>
        </div>

      </div>

      <div class="relative order-1 w-full max-w-xl mx-auto lg:order-2 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

        <div class="hidden lg:block">
          <img src='../../../../assets/images1/blob-light-rose.svg' class='absolute inset-0 w-full h-full transform lg:scale-150' alt='rose blob'>
        </div>

        <img src='../../../../assets/images1/dots-large-strip.svg' class='absolute top-0 origin-top transform -translate-x-1/2 -translate-y-8 left-1/2 lg:hidden sm:scale-100 scale-80' alt='dots'>

        <div class="relative w-full mx-auto shadow-lg rounded-3xl lg:max-w-lg lg:mr-0 lg:ml-auto">

          <div class="relative block w-full">

            <img class='absolute z-10 hidden w-40 transform lg:block -top-20 -left-20 xl:w-48 xl:-top-20 xl:-left-20' src='../../../../assets/images1/dots.svg' alt='dots'>

            <figure class='aspect-w-12 aspect-h-10 md:order-1'>
              <img src='../../../../assets/images1/about_03.webp' class='object-cover object-center w-full h-full shadow-xl rounded-3xl' alt='Family having fun'>
            </figure>
          </div>
        </div>

      </div>
    </div>

  </div>

</section>

<section class='relative w-full px-4 py-16 bg-[#4a499c] sm:py-24 sm:px-6 lg:px-8'>

  <div class='max-w-screen-xl mx-auto'>

    <div>
      <h2 class="max-w-4xl mx-auto text-center text-white h2">
        Giving your family the best dental and orthodontic care
      </h2>
      <p class="max-w-3xl mx-auto mt-4 text-xl leading-relaxed text-center text-purple-50">
        You can count on our exceptional team for high-quality care. The top priority of our team is to provide happy, healthy smiles in a friendly environment      </p>
    </div>

    <div class='mt-12 sm:grid sm:grid-cols-3 sm:gap-x-6 md:gap-x-8 lg:gap-x-12 sm:gap-y-12 gap-y-16'>

      <div class='text-center'>
        <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>25,839
        </h4>
        <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Smiles Changed</p>
      </div>

      <div class='mt-12 text-center sm:mt-0'>
        <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>40+</h4>
        <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Years of Combined Experience</p>
      </div>

      <div class='mt-12 text-center sm:mt-0'>
        <h4 class='leading-none text-white font-written text-8xl lg:text-9xl'>5 star</h4>
        <p class='mt-0.5 sm:mt-1.5 text-lg lg:text-xl font-medium text-purple-50'>Google Rating</p>
      </div>



    </div>

  </div>

</section>


<section>

  <div class='px-4 py-16 bg-[#4a499c] sm:py-24 sm:px-6 lg:px-8'>

    <div class='max-w-2xl mx-auto lg:max-w-screen-xl'>
      <div class='lg:grid lg:grid-cols-2 lg:gap-16'>
        <div class='flex items-center'>
          <h3 class='max-w-4xl text-white sm:text-center h2 lg:text-left'>
            Meet the awesome staff behind Packing House Dental & Orthodontics
          </h3>
        </div>
        <div class='flex items-center'>
          <p class='mt-5 text-xl leading-relaxed sm:text-center lg:mt-0 text-purple-50 lg:text-left'>
            Our team takes pride in working to provide the best treatment customized to our patient needs. We provide both essential and specialty treatments through our team of dentists, orthodontists and skilled specialists          </p>
        </div>
      </div>
    </div>

  </div>

  <div class='w-full h-32 bg-[#4a499c]'></div>

  <div class='px-4 sm:px-6 lg:px-8'>
    <div class="max-w-3xl mx-auto -translate-y-32 lg:max-w-screen-xl">
      <div class='grid gap-y-16 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 lg:gap-x-8'>

        <div>

          <div class='aspect-w-3 aspect-h-2'>
            <img src='../../../../assets/images1/drgary.webp' class='object-cover rounded-2xl' alt='Dr Ketcherside Redlands Orthodontist'>
          </div>

          <div class='flex items-center justify-between'>
            <div class='mt-3 text-xl font-medium'>
              <p class='font-semibold tracking-wide text-purple-900'>
                Dr Ketcherside
              </p>
              <p class='text-lg text-purple-600'>
                Orthodontist
              </p>
            </div>

            <div class='flex space-x-1.5'>



            </div>

          </div>

        </div>

        <div>

          <div class='aspect-w-3 aspect-h-2'>
            <img src='../../../../assets/images1/ryan1.webp' class='object-cover rounded-2xl' alt='Dr Ryan Redlands Dentist'>
          </div>

          <div class='flex items-center justify-between'>
            <div class='mt-3 text-xl font-medium'>
              <p class='font-semibold tracking-wide text-purple-900'>
                Dr Ryan
              </p>
              <p class='text-lg text-purple-600'>
                Dentist
              </p>
            </div>



          </div>
        </div>

        <div>

          <div class='aspect-w-3 aspect-h-2'>
            <img src='../../../../assets/images1/drwong.webp' class='object-cover rounded-2xl' alt='Dr Wong Redlands Oral Surgeon'>
          </div>

          <div class='flex items-center justify-between'>
            <div class='mt-3 text-xl font-medium'>
              <p class='font-semibold tracking-wide text-purple-900'>
                Dr Wong
              </p>
              <p class='text-lg text-purple-600'>
                Oral Surgeon
              </p>
            </div>


          </div>
        </div>





      </div>

    </div>
  </div>

</section>

<section class='py-24 bg-white sm:py-32'>

  <div class='max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8'>

    <img class='mx-auto w-72' src='../../../../assets/images1/sunrise.svg' alt="logo">

    <h2 class='max-w-3xl mx-auto mt-6 text-center text-purple-900 h1'>
      <span class="block">Ketcherside Orthodontics Inc</span>

      <span class="relative block">
        <span class='relative'>
          <img class='absolute inset-0 transform translate-y-9 sm:translate-y-11 xl:translate-y-14' src='../../../../assets/images1/underline-simple-light-purple.svg' alt="purple underline">
          <span class='relative'>Smile Brighter</span>
        </span>
      </span>

    </h2>

    <div class='flex justify-center mt-12 xl:mt-14'>
      <a href="tel:9097931595" class="text-lg font-semibold text-purple-900 bg-yellow-500  hover:bg-yellow-600 group relative leading-normal inline-flex items-center justify-center px-8 py-3.5 duration-300 ease-in-out rounded-full outline-none ">
        Call Now

        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 ml-3 group-hover:animate-horizontal-bounce" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <line x1="5" y1="12" x2="19" y2="12" />
          <line x1="15" y1="16" x2="19" y2="12" />
          <line x1="15" y1="8" x2="19" y2="12" />
        </svg>

      </a>

    </div>
  </div>

</section>
}