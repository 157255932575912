import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

  constructor() { }

  ngOnInit(): void {
  }

  sidebar = false;
  accordions: { [key: string]: boolean } = {
    dental: false,
    orthodontics: false
  };

  toggleSidebar() {
    this.sidebar = !this.sidebar;
  }

  toggleAccordion(section: keyof typeof this.accordions) {
    this.accordions[section] = !this.accordions[section];
  }
}
